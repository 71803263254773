import React, {useContext, useState} from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Form, Input, Select, Row, Col, Switch, Checkbox} from 'antd';

import {IDocType, IEspositori, ISeller} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';
export const EspositoriEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {formProps, saveButtonProps, queryResult} = useForm<IEspositori>({
        meta: {
            populate: "*"
        }
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.seller?.id,

        pagination: {
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Description"
                            name="descrizione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8} hidden={store?.id}>
                        <Form.Item
                            label="Seller"
                            name={["seller", "id"]}
                            rules={[
                                {
                                    required: !store?.id,
                                },
                            ]}
                        >
                            <Select {...sellerSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Default?"
                            name="isDefault"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Checkbox/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
