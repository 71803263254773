import {IResourceComponentsProps, useList, useTranslate} from '@refinedev/core';
import { List, useTable } from "@refinedev/antd";
import {Button, Col, Input, Row, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
export const StockMagazzino: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value} = useContext(StoreContext);
    const [store] = value;
    const d = new Date();
    const d1 = new Date();
    d.setHours(0,0,0,0);
    d1.setHours(0,0,0,0);
    const dOggi = d.setDate(d.getDate() + 1);
    const d10 = d1.setDate(d1.getDate() - 14);
    const fabbisogno = useRef(8);
    const {tableQueryResult} = useTable<any>({
        resource: "log-positions",
        meta: {populate: 'stock,seller, stock.product, stock.product.category'},

        pagination: {
            pageSize: 9999999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'isPresent',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'stock][id]',
                    operator: 'gte',
                    value: 0
                },
                {
                    field: "createdAt",
                    operator: "between",
                    value: [dayjs(d10).format('YYYY-MM-DD'), dayjs(dOggi).format('YYYY-MM-DD')]
                },
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'stock.description',
                    order: 'asc'
                }
            ]
        }
    });

    const {refetch} = useList({
        resource: 'ware-house-positions',

        meta: {
            populate: 'stock, stock.product, seller'
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            }
        ],
        sorters: [
            {
                field: 'position',
                order: 'asc'
            }
        ]
    });

    const [dataArray, setDataArray] = useState<any>([]);

    // SOMMA QTA PRELEVATE ULTIMI 14GG / 10 * 7gg / pzConf - giacenza

    useEffect(() => {
        calcolo();
    }, [tableQueryResult.data?.data])

    const calcolo = () => {
        refetch().then( (result: any) => {
            // let q = 0;
            const tempGroup: any = result.data?.data.reduce((r: any, a: any) => {
                r[a.stock.product.id] = [...(r[a.stock.product.id] || []), a];
                return r;
            }, {})
            let pos: any = [];
            for (let g in tempGroup) {
                pos.push({
                    qta: tempGroup[g].length,
                    id: Number(g)
                })
            }
        if (tableQueryResult && tableQueryResult.data?.data && tableQueryResult.data.data.length > 0) {
            const result: any = [];
            // let index = 0;
            let gIndex = 0;
            tableQueryResult.data.data.reduce( (res: any, value: any) => {
                // console.log(res);
                // console.log(value)
                gIndex = pos.findIndex((x: any) => x.id === value.stock?.product?.id);
                if (!res[value.stock?.product?.id]) {
                    res[value.stock?.product?.id] = {
                        id: value.stock.product.id,
                        codice: value.stock.product.codice,
                        description: value.stock.product.description,
                        price: value.stock.product.price,
                        priceKg: value.stock.product.priceKg,
                        giacenza: ((gIndex !== -1) ? pos[gIndex].qta: 0),
                        nrPezziStecca: value.stock?.nrPezziStecca,
                        stockMinKg: value.stock?.stockMinKg,
                        pesoFiscaleConf: value.stock?.pesoFiscaleConf,
                        isGeneric: value.stock.product.category.isGeneric,
                        isTabacco: value.stock.product.category.isTabacco,
                        stock: value.stock,
                        qta: 0 };
                    result.push(res[value.stock.product.id])
                }
                res[value.stock.product.id].qta += 1;
                return res;
            }, {});
            result.map((r: any) => {
                // console.log(r);
                const qtaR = Math.max(Math.ceil((r['qta']/14)*fabbisogno.current) - r['giacenza'], 0);
                const mlp = (r['isGeneric']) ? 1 : r['pesoFiscaleConf'];
                // console.log(r['isGeneric'], qtaR, mlp, r['pesoFiscaleConf'])
                r.qtaRiordinare = Number(qtaR * mlp).toFixed(2);
                r.totale = (r['isGeneric']) ? Number(r['pesoFiscaleConf']) * r.qtaRiordinare : Number(r['priceKg']) / (1 / r.qtaRiordinare);
            })
            // console.log(result);
            setDataArray(result)
        }
        })
    }

    const [dataExp, setDataExp] = useState<any>([]);
    // const [category, setCategory] = useState('');

    const change = (e: any, record: any, index: number) => {
        // console.log(record);
        setDataExp([]);
        let datExp: any = [];
        if (index === -1) {
            if (record && record.length > 0) {
                // console.log(record);
                for (let d of record) {
                    if (d.qtaRiordinare > 0) {
                        datExp.push({
                            codice: d.codice,
                            qta: Number(d.qtaRiordinare).toFixed(2),
                            // importo: parseFloat(d.totale).toLocaleString('de', {
                            //     minimumFractionDigits: 2
                            // })
                        });
                        setDataExp((current: any) => [...current, {
                            codice: d.codice,
                            qta: Number(d.qtaRiordinare).toFixed(2),
                            // importo: parseFloat(d.totale).toLocaleString('de', {
                            //     minimumFractionDigits: 2
                            // })
                        }]);
                    }
                }
                setTimeout(() => {
                    let ws = XLSX.utils.json_to_sheet(datExp);
                    let wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "sheet");
                    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
                    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
                    XLSX.writeFile(wb, 'Ordine_Magazzino_Stock_' + dayjs().format('DD_MM_YYYY') + '.xlsx');
                }, 500)

            }
        } else {
            // let gIndex = 0;
            if (dataArray && dataArray.length > 0) {
                // console.log('Change: ', index, e.target.value, record.id);
                const indexF = dataArray.findIndex((x: any) => x.id === record.id);
                // console.log(indexF);
                // console.log(dataArray);
                dataArray[indexF].qtaRiordinare = Number(e.target.value);
                dataArray[indexF].totale = (dataArray[indexF]['isGeneric']) ? Number(dataArray[indexF]['pesoFiscaleConf']) * Number(e.target.value) : Number(dataArray[indexF]['priceKg']) / (1 / Number(e.target.value));
                // console.log(dataArray[indexF]);
                setDataArray([...dataArray]);
                // console.log(tempData);
                let exp = [];
                for (let d of dataArray) {
                    // const consigliato = (d.product.category.isGeneric) ? Math.abs(d.giacenza - d.stockMinKg) : ((d.giacenza * d.pesoFiscaleConf) >= d.stockMinKg ? 0 : Math.abs((d.giacenza * d.pesoFiscaleConf) - d.stockMinKg ));
                    // console.log(consigliato);
                    // const tempC = d.consigliato > 0 ? d.consigliato : consigliato;
                    // const tempC = d.qtaRiordinare;
                    if (d.qtaRiordinare > 0) {
                        exp.push({
                            codice: d.codice,
                            qta: Number(d.qtaRiordinare).toFixed(2),
                            // importo: parseFloat(d.totale).toLocaleString('de', {
                            //     minimumFractionDigits: 2
                            // })
                        });
                    }
                }
                setDataExp(exp);
            }
        }

    }

    const changeFabbisogno = (value: any) => {
        console.log(value);
        fabbisogno.current = value;
        setDataArray([])
        setTimeout(() => {
            calcolo();
        },600)
    }

    return (
        <>
            <List title={'Riordino Magazzino'}>
                <Table size="small" dataSource={dataArray}
                       loading={dataArray && dataArray.length === 0}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       footer={(value) => {
                           const tot = (value && value.length > 0) ? value?.map((item: any) => item?.totale).reduce((prev, next) => prev + next).toFixed(2) : 0;
                           return <Row gutter={24}>
                               <Col span={12}>
                                   <Button
                                       type={'primary'}
                                       htmlType={'button'}
                                       onClick={() => change(null, value, -1)}>
                                       Genera Ordine
                                   </Button>
                                   {/*<CSVLink*/}
                                   {/*    separator={';'}*/}
                                   {/*    filename={'Ordine_Magazzino_Stock_' + dayjs().format('DD_MM_YYYY') + '.csv'}*/}
                                   {/*    data={dataExp}*/}
                                   {/*    asyncOnClick={true}*/}
                                   {/*    onClick={() => change(null, value, -1)}*/}
                                   {/*>*/}
                                   {/*    <Button type={'primary'}>Genera Ordine</Button>*/}
                                   {/*</CSVLink>*/}
                               </Col>
                               <Col span={12}>
                                   <div style={{
                                       textAlign: 'right',
                                       fontSize: 20
                                   }}>
                                       Totale: {parseFloat(tot.toLocaleString()).toLocaleString('de', {
                                       minimumFractionDigits: 2
                                   }) + ' €'}
                                   </div>

                               </Col>
                           </Row>
                       }}
                >
                    <Table.Column
                        dataIndex="codice"
                        key="codice"
                        title="Codice"
                        responsive={['sm']}
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.description', 'Descrizione')}
                        responsive={['sm']}
                        filters={[
                            {
                                text: 'Tabacchi',
                                value: 'isTabacco',
                            },
                            {
                                text: 'Gratta e Vinci',
                                value: 'isGeneric',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            // setCategory(value);
                            return record[value]
                        }}
                    />
                    <Table.Column
                        dataIndex="qta"
                        key="qta"
                        title={translate('pages.stock.nrPezziStecca', 'Stecche/Cf Prelevate 14gg')}
                    />
                    <Table.Column
                        dataIndex="qtaCalcolo"
                        key="qtaCalcolo"
                        title={<><span>{translate('pages.stock.stockMinKg', 'Fabbisogno')}</span><Input style={{marginLeft: 10, width: 50}}
                                                                                                        defaultValue={fabbisogno.current}
                                                                                                        onChange={(e) => changeFabbisogno(e.target.value)}></Input><span> gg</span></>}
                        render={(value, record: any) => (
                            Math.ceil(((record['qta']/14)*fabbisogno.current))
                        )}
                    />
                    <Table.Column
                        dataIndex="giacenza"
                        key="giacenza"
                        title={translate('pages.stock.giacenza', 'Giacenza Attuale')}
                    />
                    <Table.Column
                        dataIndex="qta"
                        key="qta"
                        title={translate('pages.stock.qta', 'Cf/St da Riordinare')}
                        render={(value, record: any) => (
                            Math.max(Math.ceil(((value/14)*fabbisogno.current) - record['giacenza']), 0)
                            // value + ' ' + record['giacenza']
                        )}
                    />
                    <Table.Column
                        dataIndex="qtaRiordinare"
                        key="qtaRiordinare"
                        title={translate('pages.stock.stockMinKg', 'Kg/Cf da Riordinare')}
                        filters={[
                            {
                                text: 'Tutto',
                                value: '-1',
                            },
                            {
                                text: 'Qta > 0',
                                value: '0',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            // console.log(value)
                            // const qtaR = Math.max(Math.ceil((record['qta']/2)) - record['giacenza'], 0);
                            // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                            return record.qtaRiordinare > Number(value)
                        }}
                        render={(value, record: any, index: number) => {
                            // console.log(value);
                            // const qtaR = Math.max(Math.ceil((value/2)) - record['giacenza'], 0);
                            // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                            // record.qtaRiordinare = qtaR * mlp;
                            // return  (qtaR * mlp).toFixed(2)
                            return <Input style={{width: '50%'}} size={'small'} placeholder={value}
                                          onPressEnter={(e) => change(e, record, index)}/>
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="priceKg"
                        key="priceKg"
                        title={translate('pages.stock.priceKg', 'Prezzo Kg/Cf')}
                        render={(value, record: any) => {
                            const mlp = (record['isGeneric']) ? Number(record['pesoFiscaleConf']) : Number(value);
                            return mlp.toLocaleString('de', {
                                minimumFractionDigits: 2
                            });
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="totale"
                        key="totale"
                        title={translate('pages.stock.priceKg', 'Totale €')}
                        render={(value, record: any) => {
                            // console.log(value);
                            // const qtaR = Math.max(Math.ceil((value/2)) - record['giacenza'], 0);
                            // const mlp = (record['isGeneric']) ? 1 : record['pesoFiscaleConf'];
                            // const prezzo = (record['isGeneric']) ? Number(record['pesoFiscaleConf']) * record.qtaRiordinare : Number(record['priceKg']) / (1 / record.qtaRiordinare);
                            // record.totale = prezzo;
                            return  parseFloat(value.toLocaleString('de', {
                                minimumFractionDigits: 2
                            })).toFixed(2)
                        }
                        }
                    />
                </Table>
            </List>
        </>
    );
};
