import {
    CrudFilters,
    IResourceComponentsProps, useList,
    useTranslate, useUpdate,
} from '@refinedev/core';
import {
    DateField,
    DeleteButton,
    EditButton,
    List,
    ShowButton, TagField,
    useModalForm, useSelect,
    useTable
} from '@refinedev/antd';
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Select,
    SelectProps,
    Space,
    Switch,
    Table
} from 'antd';
import {ICategory, IProduct, ISaleMovement, IStock, IWareHousePosition} from 'interfaces';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {useLocation} from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import dayjs from 'dayjs';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import * as XLSX from 'xlsx';


export const EspositoreStock: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const viewNegativi = useRef(0);
    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {
            populate: 'seller, product, product.category, giacenze_espositorises, giacenze_espositorises.espositoris',
        },
        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "giacenze_espositorises][espositoris][id]",
                    operator: "eq",
                    value: espositore
                },
                {
                    field: "giacenze_espositorises][giacenza]",
                    operator: "gt",
                    value: viewNegativi.current
                }
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        pagination: {
            pageSize: 999,
            mode: "server"
        },
    });

    const wareHousePosition = useList<IWareHousePosition>({
        resource: "ware-house-positions",
        meta: {populate: 'seller, stock, stock.product, stock.product.category'},

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 9999,
            mode: "server"
        },

        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: "stock][giacenza]",
                operator: 'gt',
                value: 0
            },
            {
                field: "dataOut",
                operator: 'null',
                value: undefined
            }
        ],

        sorters: [
            {
                field: 'stock.description',
                order: 'asc'
            }
        ]
    });

    const [detailCategoryEspositore, setDetailCategoryEspositore] = useState<any>([]);
    const [totEspositore, setTotEspositore] = useState<number>(0);
    const updateTot = () => {
        // GROUP BY CATEGORY
        const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        // console.log(tempGroup)
        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];

        for (let g in tempGroup) {
            priceCategoryEspositore.push({
                qta: Number(tempGroup[g].map((item: any) => item?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore)[0]?.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore)[0]?.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        setDetailCategoryEspositore(priceCategoryEspositore);
    }

    useEffect(() => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data.length > 0) {
            setTotEspositore(parseFloat(Number(tableQueryResult.data?.data.map(item => item.product?.price * item?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore)[0]?.giacenza).reduce((prev, next) => prev + next)).toFixed(2)));
        } else {
            setTotEspositore(0);
        }
        if (state && state.state && state.state.type === 3) {
            // handleStock();
        }
    }, [tableQueryResult.data?.data?.length]);

    useEffect(() => {
        updateTot();
    }, [tableQueryResult.isFetching])

    useEffect(() => {
        tableQueryResult.refetch();
    }, [espositore]);

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        formLoading,
        queryResult: resultForm
    } = useModalForm<IStock>({
        action: "edit",
        resource: "stocks",
        meta: {populate: 'giacenze_espositorises, giacenze_espositorises.espositoris, product, seller'},
        warnWhenUnsavedChanges: true,
    });

    useEffect(() => {
        if (resultForm && resultForm.data && resultForm.data.data) {
            const g: any = resultForm?.data?.data?.giacenze_espositorises.find((x: any) => x.espositoris.id === espositore);
            editFormProps.form?.setFieldValue('espositore', g.giacenza)
        }
    }, [formLoading]);

    const [form] = Form.useForm();

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 999999,
            mode: "server"
        }
    });

    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    const reload = (e: any) => {
        console.log(e.target.checked);
        if (e.target.checked) {
            viewNegativi.current = -999;
        } else {
            viewNegativi.current = 0;
        }
        tableQueryResult.refetch();
    }

    const updateSaleMovement = useUpdate<ISaleMovement>();
    const updateCheck = (record: any, e: any) => {
        updateSaleMovement.mutateAsync({
            resource: 'stocks',
            successNotification: false,
            id: record.id,
            values: {
                idEspositore: espositore,
                isCheck: true,
                dataControlloEspositore: new Date().toISOString(),
            }
        }).then();
    }

    const printEspositore = () => {
        const dataExport: any = tableQueryResult.data?.data.map((d: any) => ({
            Codice: d.product.codice,
            Descrizione: d.description,
            DataControllo: d.dataControlloEspositore ? dayjs(d.dataControlloEspositore).format('DD-MM-YYYY') : '',
            Pezzi: d.giacenze_espositorises?.filter((g: any) => g.espositoris.id === espositore)[0].giacenza,
            Prezzo: Number(parseFloat(d.product.price.toString()).toFixed(2)).toLocaleString('de', {
                minimumFractionDigits: 2
            }) + " €",
            Totale: Number(parseFloat((d.product.price * d.giacenze_espositorises?.filter((g: any) => g.espositoris.id === espositore)[0].giacenza).toString()).toFixed(2)).toLocaleString('de', {
                minimumFractionDigits: 2
            }) + " €"
        }));
        setTimeout(() => {
            let ws = XLSX.utils.json_to_sheet(dataExport);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
            let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
            XLSX.writeFile(wb, 'Espositore_' + dayjs().format('DD_MM_YYYY') + '.xlsx');
        }, 500)
    }

    console.log(espositore);
    return (
        <List
            title={'Espositore - Importo Totale:  € ' +
                Number(parseFloat(totEspositore.toString()).toFixed(2)).toLocaleString('de', {
                    minimumFractionDigits: 2
                })}
            headerProps={{
                extra: [
                    <>
                        <Row>
                            <Col>
                                <Button type="primary" style={{ backgroundColor: 'blue', marginRight: 10}} onClick={printEspositore}>Print</Button>
                            </Col>
                            <Col style={{ marginRight: 10}} >
                                <Popover
                                    placement="leftTop"
                                    style={{
                                        float: "right"
                                    }}
                                    content={
                                        <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                              wrapperCol={{ span: 16 }}>
                                            <Form.Item label="Cerca" name="description">
                                                <Input
                                                    allowClear={true}
                                                    placeholder="Cerca"
                                                    prefix={<Icons.SearchOutlined />}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Categoria" name="category">
                                                <Select
                                                    {...categorySelectProps}
                                                    allowClear={true}
                                                    placeholder="Categoria"

                                                />
                                            </Form.Item>
                                            <Form.Item label="Anche < 0" name="negativi">
                                                <Checkbox onChange={reload}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <Space>
                                                    <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                        Filter
                                                    </Button>
                                                    <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                        Reset
                                                    </Button>
                                                </Space>

                                            </Form.Item>
                                        </Form>}
                                    title="Custom Filter"
                                    trigger="click"
                                    open={visible}
                                    onOpenChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{marginBottom: 15}} >Filtro</Button>
                                </Popover>
                            </Col>
                        </Row>
                    </>
                ],
            }}
        >
            <Table size="small" {...tableProps}
                   key="id"
                   rowKey="id"
                   sticky={false}
                   scroll={{y: window.innerHeight - 293 + 'px'}}
                   pagination={false}
                   rowClassName={(record, index) => (record.isRadiato ? "red" : record?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore)[0]?.giacenza < 0 ? "yellow" : "")}
                   footer={data =>
                       detailCategoryEspositore.map((x: any) => {
                           return (<>
                               <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                               <span style={{
                                   marginRight: 15,
                                   color: 'coral'
                               }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                   minimumFractionDigits: 2
                               })} €</span>
                           </>)
                       })
                   }
            >
                <Table.Column
                    dataIndex="product"
                    key="product.codice"
                    width={100}
                    align={'right'}
                    title="Codice"
                    render={(value) => value?.codice}
                />
                <Table.Column
                    dataIndex="description"
                    key="description"
                    title={translate('pages.stock.product', 'Descrizione')}
                    render={(value) => value}
                />
                <Table.Column
                    dataIndex="isCheck"
                    key="isCheck"
                    align={'right'}
                    width={80}
                    title="Check"
                    render={(value, record: any) => (
                        <Switch
                            style={{
                                backgroundColor: value ? 'darkslateblue' : 'darkslateblue'
                            }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onClick={(e) => updateCheck(record, e)} defaultChecked={value}/>
                    )}
                />
                <Table.Column<IStock>
                    dataIndex="dataControlloEspositore"
                    key="dataControlloEspositore"
                    width={150}
                    align={'center'}
                    title={translate('pages.stock.scansiaPz', 'Data Controllo')}
                    render={(value, record: any) => {
                        const val = record.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore);
                        return(
                            val[0]?.dataControlloEspositore ? <DateField
                            className={(Math.floor((new Date().getTime() - new Date(val[0]?.dataControlloEspositore).getTime()) / (1000 * 3600 * 24)) > 20) ? 'checkEspositore' : ''}
                            // style={{
                            //     color: (Math.floor((new Date().getTime() - new Date(value).getTime()) / (1000 * 3600 * 24)) > 20) ? 'red' : 'white'
                            // }}
                            value={dayjs(val[0]?.dataControlloEspositore)} format={'DD/MM/YYYY HH:mm:ss'}/> : <></>
                    )}}
                />
                <Table.Column<IStock>
                    dataIndex="scansiaPz"
                    key="scansiaPz"
                    width={100}
                    align={'center'}
                    title={translate('pages.stock.scansiaPz', 'Pezzi')}
                    render={(value, record: any) => {
                        const val = record?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore);
                        // console.log(val[0])
                        return <span style={{
                            fontSize: 22
                        }}>{val[0]?.giacenza}</span>;
                    }}
                />
                <Table.Column
                    dataIndex="product"
                    key="product"
                    width={80}
                    align={'center'}
                    title={translate('pages.stock.price', 'Prezzo')}
                    render={(value) => (
                        Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + " €"
                    )}
                />
                <Table.Column
                    dataIndex="totale"
                    key="totale"
                    width={80}
                    align={'center'}
                    title={translate('pages.stock.totale', 'Totale')}
                    render={(value, record: any) => {
                        const totalPrice = record?.product?.price * record?.giacenze_espositorises?.filter((g: any) => g?.espositoris?.id === espositore)[0]?.giacenza;
                        return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + " €"
                    }
                    }
                />
                <Table.Column<{ id: string }>
                    title={translate('pages.users.action', 'Azioni')}
                    dataIndex="actions"
                    width={100}
                    align={'center'}
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => editModalShow(record.id)}
                            />
                        </Space>
                    )}
                />
            </Table>
            <Modal {...editModalProps} footer={<></>}
                   title={'Dettaglio'}
            >
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Prodotto"
                                name={["product","description"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Qta"
                                name="qta"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="giacenza"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Espositore"
                                name="espositore"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Scansia N.ro"
                                name="scansiaNr"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Min. Espositore"
                                name="scansiaStock"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </List>

    );
};
