import {IResourceComponentsProps, useExport, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, useSelect,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";

import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {IStock} from '../../interfaces';
import dayjs from 'dayjs';
import * as Icons from '@ant-design/icons';
import {CSVLink} from 'react-csv';

export const ReportGevList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const [datiGev, setDatiGev] = useState<any>([]);


    const { tableProps, tableQueryResult, searchFormProps} = useTable<any>({
        resource: "custom/getGev",
        queryOptions: {
            enabled: true
        },
        onSearch: (values: any) => {
            const dIn = new Date(values.dataIn);
            const dOut = new Date(values.dataOut);
            dIn.setHours(0,0,0,0);
            dOut.setHours(23,59,59,0);
            const dataIn = new Date(dIn.setDate(dIn.getDate() + 1)).toISOString();
            const dataOut = new Date(dOut.setDate(dOut.getDate())).toISOString();
            return [
                {
                    field: 'valIn',
                    operator: 'eq',
                    value: values.valIn
                },
                {
                    field: 'valOut',
                    operator: 'eq',
                    value: values.valOut
                },
                {
                    field: 'dataIn',
                    operator: 'eq',
                    value: dataIn
                },
                {
                    field: 'dataOut',
                    operator: 'eq',
                    value: dataOut
                }
            ];
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'espositore',
                    operator: 'eq',
                    value: espositore
                }
            ]
        },
        pagination: {
            mode: "server",
            pageSize: 99999999
        }
    });

    const [showTable, setShowTable] = useState(false);

    const confirm = () => {
        setShowTable(true);
        searchFormProps.form?.submit();
        let arrA: any = [];
        let arrV: any = [];
        tableQueryResult.refetch().then((res: any) => {
            let data = [];
            if (res.data && res.data?.data.length > 0) {
                const arr = res.data?.data.reduce((a: any, value: any) => {
                    a[value.tipo] = [...a[value.tipo] || [], value];
                    return a;
                }, []);

                if (arr && arr.hasOwnProperty('acquisti')) {
                    arrA = arr.acquisti.reduce((a: any, value: any) => {
                        a[value.prezzo] = [...a[value.prezzo] || [], value.qta];
                        return a;
                    }, []);
                }
                if (arr && arr.hasOwnProperty('vendite')) {
                    arrV = arr.vendite.reduce((a: any, value: any) => {
                        a[value.prezzo] = [...a[value.prezzo] || [], value.qta];
                        return a;
                    }, []);
                }


                data.push({
                    _gev: 'Acquisti',
                    '_0.5€': arrA.hasOwnProperty('0.5') ? arrA[0.5][0] : 0,
                    '_1€': arrA.hasOwnProperty('1') ? arrA[1][0] : 0,
                    '_2€': arrA.hasOwnProperty('2') ? arrA[2][0] : 0,
                    '_3€': arrA.hasOwnProperty('3') ? arrA[3][0] : 0,
                    '_5€': arrA.hasOwnProperty('5') ? arrA[5][0] : 0,
                    '_10€': arrA.hasOwnProperty('10') ? arrA[10][0] : 0,
                    '_15€': arrA.hasOwnProperty('15') ? arrA[15][0] : 0,
                    '_20€': arrA.hasOwnProperty('20') ? arrA[20][0] : 0,
                    '_25€': arrA.hasOwnProperty('25') ? arrA[25][0] : 0,
                });
                data.push({
                    _gev: 'Vendite',
                    '_0.5€': arrV.hasOwnProperty('0.5') ? arrV[0.5][0] : 0,
                    '_1€': arrV.hasOwnProperty('1') ? arrV[1][0] : 0,
                    '_2€': arrV.hasOwnProperty('2') ? arrV[2][0] : 0,
                    '_3€': arrV.hasOwnProperty('3') ? arrV[3][0] : 0,
                    '_5€': arrV.hasOwnProperty('5') ? arrV[5][0] : 0,
                    '_10€': arrV.hasOwnProperty('10') ? arrV[10][0] : 0,
                    '_15€': arrV.hasOwnProperty('15') ? arrV[15][0] : 0,
                    '_20€': arrV.hasOwnProperty('20') ? arrV[20][0] : 0,
                    '_25€': arrV.hasOwnProperty('25') ? arrV[25][0] : 0,
                });
                data.push({
                    _gev: 'Rim. Iniz.',
                    '_0.5€': 0,
                    '_1€': 0,
                    '_2€': 0,
                    '_3€': 0,
                    '_5€': 0,
                    '_10€': 0,
                    '_15€': 0,
                    '_20€': 0,
                    '_25€': 0,
                });
                data.push({
                    _gev: 'Rim. Fin.',
                    '_0.5€': 0,
                    '_1€': 0,
                    '_2€': 0,
                    '_3€': 0,
                    '_5€': 0,
                    '_10€': 0,
                    '_15€': 0,
                    '_20€': 0,
                    '_25€': 0,
                });
                data.push({
                    _gev: 'Tot. Diff.',
                    '_0.5€': 0,
                    '_1€': 0,
                    '_2€': 0,
                    '_3€': 0,
                    '_5€': 0,
                    '_10€': 0,
                    '_15€': 0,
                    '_20€': 0,
                    '_25€': 0,
                });
                setDatiGev(data);
                console.log(data.sort());
            } else {
                setDatiGev([]);
            }
        })
    }

    const [dataExport, setDataExport] = useState<any>([]);

    const exportDati = () => {
        setDataExport(datiGev);
    }

    // console.log(categoryOptions);
    return (
            <List title={'Report Gratta & Vinci'}>
                <Form {...searchFormProps} layout="horizontal">
                <Row gutter={24}>
                    <Col span={4}>
                            <Form.Item name={'valIn'}>
                                <Select
                                    style={{width: '100%'}}
                                    options={[
                                        {value: 0.5, label: '0.5 €'},
                                        {value: 1, label: '1 €'},
                                        {value: 2, label: '2 €'},
                                        {value: 3, label: '3 €'},
                                        {value: 5, label: '5 €'},
                                        {value: 10, label: '10 €'},
                                        {value: 15, label: '15 €'},
                                        {value: 20, label: '20 €'},
                                        {value: 25, label: '25 €'},
                                    ]}
                                    placeholder={'da...'}
                                />
                            </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'valOut'}>
                            <Select
                                style={{width: '100%'}}
                                options={[
                                    {value: 0.5, label: '0.5 €'},
                                    {value: 1, label: '1 €'},
                                    {value: 2, label: '2 €'},
                                    {value: 3, label: '3 €'},
                                    {value: 5, label: '5 €'},
                                    {value: 10, label: '10 €'},
                                    {value: 15, label: '15 €'},
                                    {value: 20, label: '20 €'},
                                    {value: 25, label: '25 €'},
                                ]}
                                placeholder={'a...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'dataIn'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'Dal...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'dataOut'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'al...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type={'primary'} style={{width: '100%'}} onClick={confirm}>Conferma</Button>
                    </Col>
                    <Col span={4}>
                        <CSVLink
                            separator={';'}
                            filename={'Report_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                            data={dataExport}
                            asyncOnClick={true}
                            onClick={exportDati}
                        >
                            <Button type={'default'} style={{width: '100%'}} icon={<Icons.ExportOutlined />} >Export</Button>
                        </CSVLink>
                    </Col>
                </Row>
                </Form>
                <hr />
                <div hidden={!showTable}>
                    <Table size="small"  rowKey="id" {...tableProps}
                        dataSource={datiGev}
                           sticky={true}
                           pagination={false}
                           scroll={{y: window.innerHeight - 350 + 'px'}}
                          >
                        <Table.Column
                            dataIndex="_gev"
                            key="_gev"
                            title={'G&V'}
                        />
                        <Table.Column
                            dataIndex="_0.5€"
                            key="_0.5€"
                            title={'0.5 €'}
                        />
                        <Table.Column
                            dataIndex="_1€"
                            key="_1€"
                            title={'1 €'}
                        />
                        <Table.Column
                            dataIndex="_2€"
                            key="_2€"
                            title={'2 €'}
                        />
                        <Table.Column
                            dataIndex="_3€"
                            key="_3€"
                            title={'3 €'}
                        />
                        <Table.Column
                            dataIndex="_5€"
                            key="_5€"
                            title={'5 €'}
                        />
                        <Table.Column
                            dataIndex="_10€"
                            key="_10€"
                            title={'10 €'}
                        />
                        <Table.Column
                            dataIndex="_15€"
                            key="_15€"
                            title={'15 €'}
                        />
                        <Table.Column
                            dataIndex="_20€"
                            key="_20€"
                            title={'20 €'}
                        />
                        <Table.Column
                            dataIndex="_25€"
                            key="_25€"
                            title={'25 €'}
                        />

                    </Table>
                </div>
            </List>
    );
};
