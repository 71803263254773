import {
    IResourceComponentsProps,
    useCreate,
    useGetIdentity,
    useList, useNavigation,
    useNotification, useOne,
    useUpdate,
} from '@refinedev/core';

import {Create, DeleteButton, RefreshButton, useForm, useModal, useSelect, useTable} from '@refinedev/antd';

import {
    Button,
    Col,
    DatePicker,
    Divider, FloatButton,
    Form,
    Input,
    InputNumber, message, Modal,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table, Tooltip,
    Typography,
} from 'antd';

import {
    ICausal,
    IDocType,
    IParameter, ISeller,
    IStock,
    IWareHousePosition,
    IWhareHouseMovement,
    IWhareHouseMovementRow
} from 'interfaces';
import React, {useContext, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {socket, SocketContext} from '../../contexts/socket';
import {Icons, toast} from 'react-toastify';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {CustomerServiceOutlined, UndoOutlined} from '@ant-design/icons';

export const WhareHouseMovementCreate: React.FC<IResourceComponentsProps> = () => {
    const inputElement: any = useRef(null) || '';
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const navigate = useNavigate();
    const [openRow, setOpenRow] = useState<boolean>(false);
    const [showSave, setShowSave] = useState<boolean>(false);
    const [totale, setTotale] = useState(0);
    const [totaleKg, setTotaleKg] = useState(0);
    const [totalExt, setTotalExt] = useState(-1);
    const [textSearch, setTextSearch] = useState<any>();
    const {data: userIdentity} = useGetIdentity<any>();
    const {formProps, saveButtonProps} = useForm<IWhareHouseMovement>({
        warnWhenUnsavedChanges: false,
    });
    const [docType, setDocType] = useState();
    const [idProgressive, setIdProgressive] = useState<any>();
    const [idMovement, setIdMovement] = useState<any>();
    const [tempSearch, setTempSearch] = useState<any>();
    const [autoFocus, setAutoFocus] = useState(false);
    const [stop, setStop] = useState(false);
    const {edit} = useNavigation();
    const getProgressiveNumberDoc = useList<IParameter>({
        resource: 'parameters',

        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: 'doc_type][id]',
                operator: 'eq',
                value: docType
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    })

    const {selectProps: docTypeSelectProps} = useSelect<IDocType>({
        resource: 'doc-types',
        optionValue: 'id',
        optionLabel: 'description',

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "client"
        }
    });

    const {selectProps: causalSelectProps} = useSelect<ICausal>({
        resource: 'causals',
        optionValue: 'id',
        optionLabel: 'description',

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "client"
        }
    });

    const updateSeller = useUpdate<ISeller>();
    const updateMagazzino = (value: boolean) => {
        updateSeller.mutateAsync({
            resource: 'sellers',
            successNotification: false,
            id: store.id,
            values: {
                isMagazzinoOff: false,
            }
        }).then(rrr => {
            message.info("Magazzino Abilitato di Default");
        });
    }

    const onSelect = (e: any) => {
        setDocType(e);
        setTimeout(() => {
            getProgressiveNumberDoc.refetch().then(result => {
                // console.log(result);
                if (result.isSuccess && result.data.data.length > 0) {
                    setIdProgressive(Number(result.data?.data[0].id));
                    formProps.form?.setFieldValue('docNumber', Number(result.data?.data[0]?.progressive) + 1);
                } else {
                    open?.({
                        type: 'error',
                        message: 'Non hai impostato nessun progressivo per il Documento',
                        description: 'Attenzione!',
                    });
                }
            })
        }, 1000)

    }

    const dataForm: any = useRef([]);
    const saveWhareHouseMovement = useCreate<IWhareHouseMovement>();
    const updateWhareHouseMovement = useUpdate<IWhareHouseMovement>();
    const updateProgressive = useUpdate<IParameter>();
    const [isSocketOk, setIsSocketOk] = useState(false);
    const checkBox = useList<any>({
        resource: "custom/checkBox"
    })
    const onConfirm = (data: any) => {
        // console.log(data);
        // CONTROLLO INCONGUENZE MAGAZZINO
        updateMagazzino(false);
        dataForm.current = data;
            checkBox.refetch().then( result => {
                console.log(result);
                // if (result && result.data && result.data?.data) {
                //     console.log('TROVATO');
                //     open?.({
                //         type: 'error',
                //         message: 'Attenzione sono state trovate incongruenze nelle posizioni di magazzino...' + result.data?.data,
                //         description: 'Attenzione!',
                //     });
                // } else {
                    console.log('NON TROVATO');
                    // CONTROLLO SOCKET
                    // socket.emit("checkSocket", {id: socket.id, data: "CONTROLLO"});
                    utilityHelper.setSocketId(socket.id).then();
                    saveWhareHouseMovement.mutateAsync({
                        resource: 'whare-house-movements',
                        successNotification: false,
                        values: {
                            description: 'Carico DDT',
                            doc_type: data.doc_type,
                            causal: data.causal,
                            loadDate: data.loadDate,
                            docNumber: data.docNumber,
                            docNumberExt: data.docNumberExt,
                            totalExt: data.totalExt,
                            isLoad: true,
                            seller: store.id,
                            user: userIdentity.id
                        }
                    }).then((r: any) => {
                        // console.log('Movement: ', r);
                        setIdMovement(r.data.data.id);
                        updateProgressive.mutate({
                            successNotification: false,
                            resource: 'parameters',
                            id: idProgressive,
                            values: {
                                progressive: formProps.form?.getFieldValue('docNumber')
                            }
                        })
                        setTimeout(() => {
                            setOpenRow(true);
                            setTimeout(() => {
                                inputElement?.current?.focus();
                            }, 600)
                            edit('whare-house-movements', r.data.data.id);
                            setTotale(0);
                        }, 1000);

                    });
                // }
            });
    }

    const alertTelegram = useCreate();
    const onSave = () => {
        checkBox.refetch().then( result => {
            console.log(result);
            if (result && result.data && result.data?.data) {
                console.log('TROVATO');
                open?.({
                    type: 'error',
                    message: 'Attenzione sono state trovate incongruenze nelle posizioni di magazzino...' + result.data?.data + ' Il Carico è stato registrato correttamente!',
                    description: 'Attenzione!',
                });
                // if (totale !== formProps.form?.getFieldValue('totalExt')) {
                //     utilityHelper.seller().then( (r: any) => {
                //         alertTelegram.mutate({
                //             resource: 'custom/sendAlertTelegram',
                //             successNotification: false,
                //             values: {
                //                 telegramId: r.telegramId,
                //                 rivenditore: r.description,
                //                 data: {
                //                     numDoc: dataForm.current.docNumberExt,
                //                     dataDoc: dayjs(dataForm.current.loadDate, 'DD/MM/YYYY')
                //                 }
                //             }
                //         })
                //     });
                // }
                updateWhareHouseMovement.mutateAsync({
                    resource: 'whare-house-movements',
                    successNotification: false,
                    id: idMovement,
                    values: {
                        isLoad: false,
                        isTemporary: false,
                        total: totale,
                        totalExt: formProps.form?.getFieldValue('totalExt')
                    }
                }).then(rrr => {
                    navigate('/whare-house-movements');
                });
            } else {
                // if (totale !== formProps.form?.getFieldValue('totalExt')) {
                //     utilityHelper.seller().then( (r: any) => {
                //         alertTelegram.mutate({
                //             resource: 'custom/sendAlertTelegram',
                //             successNotification: false,
                //             values: {
                //                 telegramId: r.telegramId,
                //                 rivenditore: r.description,
                //                 data: {
                //                     numDoc: dataForm.current.docNumberExt,
                //                     dataDoc: dayjs(dataForm.current.loadDate, 'DD/MM/YYYY')
                //                 }
                //             }
                //         })
                //     });
                // }
                updateWhareHouseMovement.mutateAsync({
                    resource: 'whare-house-movements',
                    successNotification: false,
                    id: idMovement,
                    values: {
                        isLoad: false,
                        isTemporary: false,
                        total: totale,
                        totalExt: formProps.form?.getFieldValue('totalExt')
                    }
                }).then(rrr => {
                    navigate('/whare-house-movements');
                });
            }
        });
    }
    // const onSaveTemp = () => {
    //     // console.log('Salvo: ', formProps.form?.getFieldValue('totalExt'), totale);
    //     updateWhareHouseMovement.mutateAsync({
    //         resource: 'whare-house-movements',
    //         successNotification: false,
    //         id: idMovement,
    //         values: {
    //             isLoad: true,
    //             isTemporary: true,
    //             total: totale
    //         }
    //     }).then(rrr => {
    //         if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data) {
    //             wareHousePosition.refetch().then(pos => {
    //                 setTimeout(() => {
    //                     if (pos && pos.data && pos.data.data.length > 0) {
    //                         for (let x of tableQueryResult.data.data) {
    //                             // console.log(x);
    //                             // console.log(pos);
    //                             // Imposto WareHousePosition to isTemp = true
    //                             const indexHousePosition = pos.data.data.findIndex(wp => wp.position === x.position);
    //                             // console.log(indexHousePosition);
    //                             if (indexHousePosition !== -1) {
    //                                 updateWareHousePosition.mutate({
    //                                     resource: 'ware-house-positions',
    //                                     successNotification: false,
    //                                     id: Number(pos.data?.data[indexHousePosition].id),
    //                                     values: {
    //                                         isTemp: true
    //                                     }
    //                                 })
    //                             }
    //                             // console.log(tableQueryResult);
    //                             // console.log(wareHousePosition);
    //                         }
    //                         navigate('/whare-house-movements');
    //                     }
    //                 }, 600);
    //             })
    //         }
    //     });
    // }

    const updateWareHousePosition = useUpdate<IWareHousePosition>();

    // const wareHousePosition = useList<IWareHousePosition>({
    //     resource: 'ware-house-positions',
    //     meta: {populate: 'seller, stock, stock.product'},
    //
    //     queryOptions: {
    //         enabled: false
    //     },
    //
    //     pagination: {
    //         pageSize: 99999
    //     },
    //
    //     filters: [
    //         {
    //             field: 'seller.id',
    //             operator: 'eq',
    //             value: store.id,
    //         },
    //         {
    //             field: 'dataOut',
    //             operator: 'null',
    //             value: undefined
    //         }
    //     ]
    // });

    const barcodeSearchData = useList<IStock>({
        resource: 'stocks',

        queryOptions: {
            enabled: false,
        },

        meta: {
            populate: 'product'
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const {selectProps, queryResult} = useSelect<IStock>({
        resource: 'stocks',
        optionLabel: 'description',
        optionValue: 'id',

        queryOptions: {
            enabled: true,
        },

        meta: {
            populate: 'product'
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'description',
                        operator: 'contains',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "client"
        }
    });

    const [disableInput, setDisableInput] = useState(false);
    const handleSearchBarcode = (e: any) => {
        // console.log(e.key);
        if (e.keyCode === 13) {
            setDisableInput(true);
            if (!stop) {
                        // console.log(tempSearch);
                        setTextSearch(tempSearch);
                        setTimeout(() => {
                            barcodeSearchData.refetch().then(res => {
                                // console.log(res);
                                if (res && res.data && res.data.data.length > 0) {
                                    addWhareHouseMovementRow.mutateAsync({
                                        resource: 'whare-house-movement-rows',
                                        successNotification: false,
                                        values: {
                                            whare_house_movement: idMovement,
                                            product: res.data?.data[0]?.product?.id,
                                            stock: res.data?.data[0]?.id,
                                            seller: store.id,
                                            users: userIdentity.id,
                                            description: res.data?.data[0]?.product?.description,
                                            price: res.data?.data[0]?.product?.price,
                                            aggio: res.data?.data[0]?.aggio,
                                            nrPezziStecca: res.data?.data[0]?.nrPezziStecca,
                                            qta: 1,
                                            isPresent: true
                                        }
                                    }).then( tot => {
                                        setTempSearch('');
                                        refetchTotale();
                                        tableQueryResultPagination.refetch();
                                    })
                                } else {
                                    setTempSearch('');
                                    open?.({
                                        type: 'error',
                                        message: 'Articolo non presente in Stock...',
                                        description: 'Attenzione!',
                                    });
                                    setDisableInput(false);
                                    setTimeout(() => {
                                        inputElement?.current?.focus();
                                    }, 1000)

                                }

                            })
                        }, 300)

            } else {
                toast("Sono State Trovate Una o più Referenze inserite ma senza Codice a Barre ...");
            }
        }
    };

    const handleSearch = (value: string) => {
        setTextSearch(value);
    };

    const addWhareHouseMovementRow = useCreate<IWhareHouseMovementRow>();
    const handleSelect = (value: any) => {
        // console.log(value);
        // console.log(queryResult);
        const c: any = queryResult.data?.data.findIndex(x => x.id === value);
        if (c !== -1) {
            // console.log(c);
            // console.log(queryResult.data?.data[c]);
            addWhareHouseMovementRow.mutateAsync({
                resource: 'whare-house-movement-rows',
                successNotification: false,
                values: {
                    whare_house_movement: idMovement,
                    product: queryResult.data?.data[c]?.product?.id,
                    stock: queryResult.data?.data[0]?.id,
                    seller: store.id,
                    users: userIdentity.id,
                    description: queryResult.data?.data[c]?.product?.description,
                    price: queryResult.data?.data[0]?.product?.price,
                    aggio: queryResult.data?.data[0]?.aggio,
                    nrPezziStecca: queryResult.data?.data[0]?.nrPezziStecca,
                    qta: 1,
                    isPresent: true
                }
            }).then( tot => {
                refetchTotale();
                tableQueryResultPagination.refetch();
            });
        } else {
            open?.({
                type: 'error',
                message: 'Articolo non presente in Stock...',
                description: 'Attenzione!',
            });
        }
    };

    const {data, refetch: refetchTotale} = useOne<any>({
        queryOptions: {
            enabled: false
        },
        resource: 'custom/getTotaleCarico?idMovement=' + idMovement + '&idSeller=' + store.id,
    });

    const {tableProps: tableWithPagination, tableQueryResult: tableQueryResultPagination} = useTable<IWhareHouseMovementRow>({
        resource: 'whare-house-movement-rows',
        queryOptions: {
            enabled: false
        },
        meta: {
            populate: 'seller, product, product.category, stock, whare-house-movement'
        },
        filters: {
            permanent: [
                {
                    field: 'whare_house_movement][id]',
                    operator: 'eq',
                    value: idMovement
                },
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    // useEffect(() => {
    //     if (idMovement) {
    //         tableQueryResult.refetch();
    //     }
    //  }, [tableQueryResult.data])


    const updateTot = () => {
        // if (tableQueryResult.isSuccess && tableQueryResult.data.data.length > 0) {
        //     // setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => item?.product?.price * (Math.round(1000 / (item?.product?.priceKg / item?.product?.price)) / 2)).reduce((prev, next) => prev + next)).toFixed(2)));
        //     setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => ((item?.price * item?.nrPezziStecca) - ((item?.price * item?.nrPezziStecca) / 100) * item?.aggio)).reduce((prev, next) => prev + next)).toFixed(2)));
        //     const getKg = tableQueryResult.data?.data.filter((d: any) => d?.product?.category?.isTabacco);
        //     // console.log(getKg);
        //     if (getKg && getKg.length > 0) {
        //         setTotaleKg(parseFloat(Number(tableQueryResult.data?.data.map(item => ((item?.stock?.pesoFiscaleConf * item?.nrPezziStecca)/10)).reduce((prev, next) => prev + next)).toFixed(2)));
        //     } else {
        //         setTotaleKg(0);
        //     }
        // } else {
        //     setTotale(0);
        // }
        setTotale(data?.data.totale);
        setTotaleKg(data?.data.totaleKg);
        if (formProps.form?.getFieldValue('totalExt') === data?.data.totale) {
            setShowSave(true);
        } else {
            setShowSave(false);
        }
    }

    useEffect(() => {
        updateTot();
    })

    useEffect(() => {
        setTimeout(() => {
            inputElement?.current?.focus();
            console.log('AGGIORNO INPUT FOCUS')
        }, 1000)
    }, [disableInput]);

    useEffect(() => {
        // socket.on('sendCheckResponse', async (data: any) => {
        //     console.log('Risposta Controllo Socket: ', data.data);
        //     if (!data.data) {
        //         console.log("Aggiorno Socket: ", socket.id);
        //         utilityHelper.setSocketId(socket.id).then();
        //     } else {
        //         utilityHelper.setSocketId(socket.id).then();
        //         console.log("Update Socket: ", socket.id);
        //         //setIsSocketOk(true);
        //     }
        // });
        socket.on("alertLettureMultiple", async (data: any, error: any) => {
            console.log(data);
            setStop(true);
        });
        socket.on("sendStart", async (data: any, error: any) => {
            console.log('SEND: ', data);
            setStop(false);
            refetchTotale();
            tableQueryResultPagination.refetch();
            setDisableInput(false);
        });
        socket.on('sendWhareHouse', async (data: any, error: any) => {
            console.log('WARE: ', data);
            refetchTotale();
            tableQueryResultPagination.refetch();
            setDisableInput(false);
        });
        socket.on('alertArticoloNonPresente', async (data: any, error: any) => {
            // console.log(data);
            open?.({
                type: 'error',
                message: 'Si è verificato un errore nel Carico...Articolo non presente in Anagrafica Articoli',
                description: 'Attenzione',
            });
        });
        socket.on('alertRiposizionamento', async (data: any, error: any) => {
            console.log(data);
            if (data.stop) {
                Modal.confirm({
                    footer: false,
                    title: 'Attenzione',
                    content:
                        <Title level={5}>Hai prelevato accidentalemente una Stecca
                            - Attendo Riposizionamento corretto nella posizione: {data.position} - procedura di carico bloccata!</Title>
                    ,
                });
            } else {
                Modal.destroyAll()
            }
        });
        socket.on('alertRiposizionamentoAbusivo', async (data: any, error: any) => {
            console.log(data);
            if (data.stop) {
                Modal.confirm({
                    footer: false,
                    title: 'Attenzione',
                    content:
                        <Title level={5}>Hai Occupato abusivamente la posizione: {data.position} - procedura di carico bloccata!</Title>
                    ,
                });
            } else {
                Modal.destroyAll()
            }
        });
        return () => {
            // socket.off('sendCheckResponse');
            socket.off('sendWhareHouse');
            socket.off('alertArticoloNonPresente');
            socket.off('alertLettureMultiple');
            socket.off('sendStart');
            socket.off('alertRiposizionamento');
            socket.off('alertRiposizionamentoAbusivo');
        };
    }, [])

    const {Title} = Typography;

    const handleFocus = (event: any) => {
        event.target.select();
    };

    const updatePosition = () => {
        refetchTotale().then();
        tableQueryResultPagination.refetch().then();
    }

    return (
        <Create
            footerButtons={
            <div style={{marginRight: 150}}>
                    {/*<Button type={'default'} htmlType={'button'} onClick={onSaveTemp} hidden={true}>Salva Temporaneo</Button>*/}
                    <Button type={'primary'} htmlType={'submit'} onClick={onSave} >Salva</Button>
            </div>
        }>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={onConfirm}
            >
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Tipo"
                            name="doc_type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...docTypeSelectProps} onSelect={onSelect}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Causale"
                            name="causal"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...causalSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="loadDate"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="N. Interno"
                            name="docNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Rif. DDT"
                            name="docNumberExt"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tot. Doc Ext"
                            name="totalExt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Devi Inserire il Totale Documento'
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%', appearance: 'textfield'}}
                                precision={2}
                                decimalSeparator={','}
                                onChange={updateTot}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Action"
                            name="buttonAction"
                        >
                            <Button style={{width: '100%'}} type={'primary'} htmlType={'submit'} hidden={!idProgressive}
                                    disabled={idMovement > 0}>Conferma</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div hidden={!openRow}>
                <Divider/>
                <Row gutter={24}>
                    <Col span={12} style={{marginBottom: 20}}>
                        <Input
                            disabled={disableInput}
                            style={{
                            width: '100%'
                        }}
                               name={'codice'}
                               placeholder={'Codice a barre...'}
                               ref={inputElement}
                               onFocus={(e) => handleFocus(e)}
                               onChange={event => setTempSearch(event.target.value)}
                               onKeyDown={(e) => handleSearchBarcode(e)}
                               allowClear={true}
                               value={tempSearch}
                        />
                    </Col>
                    <Col span={12} style={{marginBottom: 20}}>
                        <Select style={{
                            width: '100%'
                        }}
                                placeholder={'Ricerca Manuale...'}
                                {...selectProps}
                                onSearch={(e) => handleSearch(e)}
                                allowClear={true}
                                onSelect={(e) => handleSelect(e)}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableWithPagination}
                               // pagination={false}
                               // scroll={{y: 400}}
                        >
                            <Table.Column
                                dataIndex="product"
                                key="product.codice"
                                title="Codice"
                                render={(value) => value?.codice}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product_desc"
                                title="Descrizione"
                                render={(value) => value?.description}
                            />
                            <Table.Column
                                dataIndex="price"
                                key="price"
                                title="Price"
                                render={(value) => (
                                    Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                )}
                            />
                            <Table.Column
                                dataIndex="aggio"
                                key="aggio"
                                title="Aggio %"
                            />
                            <Table.Column
                                dataIndex="stock"
                                key="stock"
                                title="Price Package"
                                render={(value, record: any) => {
                                    const pricePak = ((record?.price * value?.nrPezziStecca) - (((record?.price * value?.nrPezziStecca) / 100) * value?.aggio));
                                    return Number(parseFloat(pricePak.toString()).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                }
                                }
                            />
                            <Table.Column
                                dataIndex="position"
                                key="position"
                                title="Position"
                            />
                            <Table.Column<{ id: string }>
                                title={'Actions'}
                                dataIndex="actions"
                                render={(_, record) => (
                                    <Space>
                                        <DeleteButton
                                            resource="whare-house-movement-rows"
                                            hideText
                                            size="small"
                                            onSuccess={(v: any) => {
                                                refetchTotale();
                                                tableQueryResultPagination.refetch();
                                                setDisableInput(false);
                                            }}
                                            recordItemId={record.id}
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
            <div hidden={!openRow}>
                <Divider/>
                <Row gutter={24}>
                    <Col span={12}>
                        <Title level={3} style={{textAlign: 'center'}}>Totale Kg: {(
                            totaleKg ? Number(parseFloat(totaleKg?.toString()).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) : 0 + ' Kg'
                        )}</Title>
                    </Col>
                    <Col span={12}>
                        <Title level={3} style={{textAlign: 'center'}}>Totale: {(
                            totale ? Number(parseFloat(totale?.toString()).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) : 0 + ' €'
                        )}</Title>
                    </Col>
                </Row>
            </div>
            <div>
                <Tooltip title={'Aggiorna Posizioni'}>
                    <FloatButton
                        shape="circle"
                        type="default"
                        style={{ right: 170 }}
                        onClick={updatePosition}
                        icon={<UndoOutlined />}
                    />
                </Tooltip>

            </div>
        </Create>
    );
};
