import {CrudFilters, IResourceComponentsProps, useList, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Col, Row} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import "@ant-design/charts/dist/index.css";
import {Area} from '@ant-design/charts';

export const ReportGraficoList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const {data: dataTabacchi, refetch, isSuccess} = useList<any>({
        resource: "custom/reportGraficos",
        queryOptions: {
            enabled: false
        },
        filters: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
            {
                field: 'espositore',
                operator: 'eq',
                value: espositore
            }
            ]
    });

    const {data: dataTurn, refetch: refetchDataTurn, isSuccess: isSuccessDataTurn} = useList<any>({
        resource: "custom/reportTurnGraficos",
        queryOptions: {
            enabled: true
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const [data, setData] = useState([]);
    const [dataTurnA, setDataTurnA] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, [isSuccess]);

    useEffect(() => {
        asyncFetchTurn();
    }, [isSuccessDataTurn]);
    const asyncFetch = () => {
        refetch().then((response: any) => {
                console.log(response);
                setData(response.data.data);
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    const asyncFetchTurn = () => {
        const d: any = [];
        refetchDataTurn().then((response: any) => {
            console.log(response);
            response.data.data.map((x: any) => {
                d.push({
                    descrizione: x.descrizione,
                    incasso: (x?.incasso ? x.incasso : 0),
                    mese: x.mese,
                    nMese: x.nMese
                });
            });
            console.log(d);
            setDataTurnA(d);
        })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    const config = {
            data: data,
            isStack: true,
            xField: 'mese',
            yField: 'numero',
            seriesField: 'descrizione',
        marginRatio: 0,
        label: {
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        smooth: true,
        slider: {
            start: 0.1,
            end: 0.9,
            trendCfg: {
                isArea: true,
            },
        },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    const configTurn = {
        data: dataTurnA,
        isStack: true,
        xField: 'mese',
        yField: 'incasso',
        seriesField: 'descrizione',
        marginRatio: 0,
        locale: 'it-IT',
        label: {
            style: {
                fill: 'white',
                opacity: 0.9,
                fontSize: 14,
            },
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjusts_color',
                },
            ],
        },
        smooth: true,
        slider: {
            start: 0.1,
            end: 0.9,
            trendCfg: {
                isArea: true,
            },
        },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    return (
            <List
                title={'Report Incasso'}
            >
                {/*<Row gutter={24}>*/}
                {/*    <Col span={24}>*/}
                {/*        { isSuccess &&*/}
                {/*            <Area {...config} />*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row gutter={24}>
                    <Col span={24}>
                        { isSuccessDataTurn &&
                            <Area {...configTurn} legend={{
                                layout: 'vertical',
                                position: 'right-top'
                            }}/>
                        }
                    </Col>
                </Row>
            </List>
    );
};
