import React, {useContext, useEffect, useRef, useState} from 'react';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Popconfirm, Popover, Switch, Tooltip} from 'antd';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';

import {
    CrudFilters,
    useCreate,
    useGetIdentity,
    useList,
    useNotification,
    useOne,
    useUpdate,
    useUpdateMany,
} from '@refinedev/core';

import {StoreContext} from '../../contexts/StoreContext';
import {ISaleMovement, IStock, ITurn} from '../../interfaces';
import {TurnContext} from '../../contexts/TurnContext';
import { useNavigate } from "react-router-dom";
import {useTable} from '@refinedev/antd';
import {toast} from 'react-toastify';

export const TurnButton: React.FC<any> = () => {
    const { data: userIdentity } = useGetIdentity<any>();
    const {value, value2, value3, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const [turn, setTurn] = useContext(TurnContext);
    const [disableOpenTurn, setDisableOpenTurn] = useState<boolean>(false);
    const [disableCloseTurn, setDisableCloseTurn] = useState<boolean>(false);
    const { open, close } = useNotification();
    const navigate = useNavigate();

    const {data} = useList<ITurn>({
        resource: 'turns',

        queryOptions: {
            enabled: true
        },

        meta: {
            populate: 'user, seller'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store?.id
            },
            {
                field: 'user][id]',
                operator: 'eq',
                value: userIdentity?.id
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            },
        ]
    });


    const addTurn = useCreate<ITurn>();
    const updateTurn = useUpdate<ITurn>();
    const openTurn = (event: any) => {
        event.currentTarget.disabled = true;
        setTimeout(() => {
            addTurn.mutateAsync({
                resource: 'turns',
                successNotification: false,
                values: {
                    description: 'Apertura Turno',
                    dataIn: new Date(),
                    user: userIdentity.id,
                    seller: store.id
                }
            }).then( res => {
                open?.({
                    type: "success",
                    message: "Turno Aperto Correttamente...",
                    description: 'Info',
                });
                setOpenTurnConfirm(false);
                setDisableOpenTurn(true);
                setDisableCloseTurn(false);
            })
        }, 500)

    }

    const chiusuraTurn = useCreate<ITurn>();

    const closeTurn = () => {
        if (data && data.data && data.data[0].user.id === userIdentity.id) {
            hidePopconfirm();
            chiusuraTurn.mutateAsync({
                resource: 'custom/chiusuraTurno',
                successNotification: false,
                values: {
                    turn: turn.id,
                    seller: store.id,
                    user: userIdentity?.id,
                    espositore: espositore
                }
            }).then((r: any) => {
                if (r && r.data && r.data.success) {
                    open?.({
                        type: "success",
                        message: "Turno Chiuso Correttamente...",
                        description: 'Info',
                    });
                    setDisableOpenTurn(false);
                    setDisableCloseTurn(true);
                    navigate('turns', {state: { isOpen: true, record: turn}})
                } else {
                    open?.({
                        type: "error",
                        message: "Si è verificato un errore nella Chiusura del Turno!",
                        description: 'Info',
                    });
                }
            })
        }

    }

    useEffect(() => {
        if (data && data.data.length > 0 && !data.data[0].dataOut) {
            setDisableOpenTurn(true);
            setDisableCloseTurn(false);
            setTurn(data.data[0]);
        } else {
            setDisableCloseTurn(true);
            setTurn(data?.data[0]);
        }
    })

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openTurnConfirm, setOpenTurnConfirm] = useState(false);
    const showPopconfirm = () => {
        setOpenConfirm(true);
    };

    const hidePopconfirm = () => {
        setOpenConfirm(false);
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpenConfirm(false);
    };

    const handleTurnCancel = () => {
        // console.log('Clicked cancel button');
        setOpenTurnConfirm(false);
    };

    const onCheck = async () => {
        showPopconfirm();
    };

    const onTurnCheck = async () => {
        setOpenTurnConfirm(true);
    };

    const [autoTurn, setAutoTurn] = value3;

    const updateSellerTurn = useUpdate();

    const switchAutoTurn = (e: any) => {
        if (!disableOpenTurn || autoTurn) {
            setAutoTurn(e);
            updateSellerTurn.mutateAsync({
                successNotification: false,
                resource: 'users',
                id: userIdentity?.id,
                values: {
                    autoTurn: e
                }
            }).then(res => {
                toast.info(e ? 'Turno automatico Abilitato': 'Turno Manuale Abilitato')
            })
        } else {
            toast.error('Non puoi abilitare il Turno Automatico - Devi prima chiudere il Turno Attuale');
        }

    }

    // console.log("AUTO TURN: ", autoTurn)
    return (
        <>
            {!autoTurn && <>
                <Tooltip title={'Apri il Turno'} placement={'top'}>
                    <Popconfirm
                        title="Attenzione"
                        open={openTurnConfirm}
                        description="Sei Sicuro di voler aprire il Turno?"
                        onConfirm={openTurn}
                        cancelText={'No'}
                        okText={'Si! Sono Sicuro'}
                        onCancel={handleTurnCancel}
                    >
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: disableCloseTurn ? 'green' : 'gray',
                                marginRight: 10, width: 30, marginBottom: 10 }}
                            disabled={disableOpenTurn} onClick={onTurnCheck}
                            icon={<Icons.ClockCircleFilled />}
                        >
                        </Button>
                    </Popconfirm>
                </Tooltip>
                <Tooltip title={'Chiudi il Turno'} placement={'right'}>
                    <Popconfirm
                        title="Attenzione"
                        open={openConfirm}
                        description="Sei Sicuro di voler confermare la chiusura Turno?"
                        onConfirm={closeTurn}
                        cancelText={'No'}
                        okText={'Si! Sono Sicuro'}
                        onCancel={handleCancel}
                    >
                        <Button style={{
                            color: 'white',
                            backgroundColor: disableCloseTurn ? 'grey' : 'red',
                            marginRight: 0, width: 30}} disabled={disableCloseTurn} onClick={onCheck}
                                icon={<Icons.ClockCircleOutlined />}
                        >
                        </Button>
                    </Popconfirm>
                </Tooltip>
            </>}
            <Switch
                checkedChildren={'Turno Automatico'}
                unCheckedChildren={'Turno Manuale'}
                checked={autoTurn}
                onChange={(e: any) => {
                switchAutoTurn(e)
            }}></Switch>
        </>
    );
};
