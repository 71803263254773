import React, {useContext, useEffect, useRef, useState} from 'react';
import { TagField, useModal, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Avatar, Badge, Button, Checkbox, message, Modal, Space, Table, Tooltip} from 'antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import Icon from "@ant-design/icons";

import {useCreate, useGetIdentity, useList, useUpdate} from '@refinedev/core';
import {StoreContext} from '../../contexts/StoreContext';
import {IRestock, IStatusWareHouse, IWareHousePosition} from '../../interfaces';
import {socket} from '../../contexts/socket';
import {toast} from 'react-toastify';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
export const RestockButton: React.FC<any> = () => {
    const {data: userIdentity} = useGetIdentity<any>();
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    // console.log(store);
    const {show, close, modalProps} = useModal();
    const onlyPosition = useRef(false);
    const [dataRestock, setDataRestock] = useState<any>([]);
    const [isPrenotato, setIsPrenotato] = useState(false);
    const {tableQueryResult, tableProps} = useTable<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: false
        },
        pagination: {
            pageSize: 9999
        },
        meta: {
            populate: 'product, product.category, seller, stock'
        },
        syncWithLocation: false,
        filters: {
            defaultBehavior: "replace",
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'espositore][id]',
                    operator: 'eq',
                    value: espositore
                },
                {
                    field: 'isRiassortito',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'qtaRiassortimento',
                    operator: 'gt',
                    value: 0
                },
            ]
        }
    });

    useEffect(() => {
        if(store.id) {
            tableQueryResult.refetch();
        }
    }, [store.id]);

    const {data, refetch, isFetched}  = useList<IWareHousePosition>({
        resource: "ware-house-positions",

        meta: {
            populate: 'seller,stock,stock.product,user'
        },

        queryOptions: {
            enabled: true
        },

        pagination: {
          pageSize: 999
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'dataIn',
                operator: 'nnull',
                value: undefined
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: undefined
            },
        ]
    });

    const reloadData = () => {
        setDataRestock([]);
        setTimeout(() => {
            refetch().then((r: any) => {
                tableQueryResult.refetch().then( (res: any) => {
                    // console.log(res);
                    for (let d of res.data.data) {
                        const pos: any = r?.data?.data?.filter((p: any) => p.stock?.product?.codice === d.product?.codice);
                        d.position = [];
                        for (let q = 0; q < Number(d.qtaRiassortimento); q++) {
                            if (pos[q]?.position) {
                                d.position.push(pos[q]?.position ? pos[q]?.position : null);
                            }
                        }
                        // const pos: any = r?.data?.data?.find((p: any) => p.stock?.product?.codice === d.product?.codice);
                        // d.position = pos?.position ? pos?.position : null;
                    }
                    if (!onlyPosition.current) {
                        console.log('TRUE')
                        // console.log(res.data?.data);
                        setDataRestock(res.data.data.filter((x: any) => x.position.length > 0))
                    } else {
                        console.log('FALSE')
                        setDataRestock(res.data.data)
                    }
                })
            })
        }, 500)
    }

    useEffect(() => {
        reloadData();
        socket.on("alertRiassortimento", async (data: any, error: any) => {
            // console.log(data);
            reloadData();
            toast("E stata prelevata una stecca per riassortimento dalla posizione: " + data?.position);
        });

        socket.on("alertAddRiassortimentoDaCarico", async (data: any, error: any) => {
            // console.log(data);
            reloadData();
            toast("E stato Consigliato un Riassortimento trovato fase di Carico: " + data?.product);
        });

        socket.on("alertAddRiassortimento", async (data: any, error: any) => {
            // console.log(data);
            reloadData();
        });

        socket.on("alertPrenotazione", async (data: any, error: any) => {
            console.log("Alert Prenotazione:", data);
            setIsPrenotato(data.data)
        });


        return () => {
            socket.off('alertRiassortimento');
            socket.off('alertAddRiassortimento');
            socket.off('alertPrenotazione');
        };
    }, [])

    const showModal = () => {
        reloadData();
        onlyPosition.current = false;
        setTimeout(() => {
            show();
        }, 500)
    }

    const onChange = (e: CheckboxChangeEvent) => {
        onlyPosition.current = e.target.checked;
        reloadData();
    };

    const {data: statusWareHouse} = useList<IStatusWareHouse>({
        resource: 'status-ware-houses',
        meta: {populate: 'seller, device, led'},

        queryOptions: {
            enabled: true
        },

        pagination: {
            mode: 'server',
            pageSize: 999999
        },
        filters: [
            {
                field: 'seller.id',
                operator: 'eq',
                value: store.id,
            },
            {
                field: 'led][device_type][isLuminaria]',
                operator: 'eq',
                value: true
            }
        ]
    });

    const callLuminara = useCreate();
    const updateWareHouse = useCreate();
    const accendiLuminaria = () => {
        console.log("DATA RESTOCK: ", dataRestock);
        // PRENDO TUTTE LE POSITON
        const allPosition: any = [];
        for (let pos of dataRestock) {
            if (pos.position && pos.position.length > 0) {
                for (let p of pos.position) {
                    allPosition.push(p);
                }
            }
        }
        console.log("POSITION ALL: ", allPosition);
        // CONTROLLO SE POSSO PRELEVARE
        updateWareHouse.mutateAsync({
            successNotification: false,
            resource: 'custom/controlloPrenotazione',
            values: {
                idsPosition: allPosition,
                idEspositore: espositore,
                idSeller: store.id,
                idUser: userIdentity?.id,
            }
        }).then((res: any) => {
            console.log(res);
            if (res && res.data && !res.data.success) {
                message.error(res.data.message)
            } else {
                for (let pp of allPosition) {
                    const position = pp;
                    // console.log("Position Split: ", position.split(/[a-zA-Z]/g));
                    const getModule = position.split(/[a-zA-Z]/g);
                    const getColumn = position.match(/[a-zA-Z]/g);
                    const nModulo = getModule[0];
                    const nPosition = getModule[1];
                    const nColumn = getColumn[0];
                    const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(nModulo) && String.fromCharCode(d.module + 65) === nColumn));
                    // const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(pp.substring(0, 1)) && String.fromCharCode(d.module + 65) === pp.substring(1, 2)));
                    // console.log("Dispositivo Trovato: ", findDevice);
                    // console.log("Dispositivo Number: ", nPosition);
                    // console.log("Dispositivo Module: ", nModulo);
                    // console.log("Dispositivo Colonna: ", nColumn);
                    if (findDevice) {
                        const numeroCella = nPosition;
                        // const numeroCella = pp.length <= 3 ? pp.substring(2, 3) : pp.substring(2, 4);
                        // console.log(numeroCella);
                        const serialeLed = findDevice?.led?.serial;
                        // console.log('Seriale LED: ', serialeLed);
                        const findRiga = Math.floor(numeroCella/2) + 1;
                        let led = '';
                        setTimeout(() => {
                            for(let r of findDevice["riga" + findRiga].split(',')) {
                                // console.log(String(r).padStart(4, '0'));
                                led += String(r).padStart(4, '0') + "," + (numeroCella % 2 === 0 ? 'S' : 'D') + ",1;";
                            }
                            // console.log(led);
                            const nLed = String(findDevice["riga" + findRiga].split(',').length).padStart(6, '0');
                            // console.log(nLed);
                            callLuminara.mutateAsync({
                                resource: 'custom/controlloLuminaria',
                                successNotification: false,
                                values: {
                                    comando: `[${serialeLed}|${nLed}|${led}]`,
                                    seller: store.id,
                                    isPrenotazione: true,
                                }
                            }).then((res: any) => {
                                message.info("Dati inviati al Master LED!");

                            })
                        }, 300)

                    }
                }
            }
        });
    }


    // console.log(statusWareHouse);

    const spegniLuminaria = () => {
        callLuminara.mutateAsync({
            resource: 'custom/spegniLuminaria',
            successNotification: false,
            values: {
                seller: store.id,
                user: userIdentity?.id,
            }
        }).then((res: any) => {
            console.log(res);
            if (res && res.data && res.data.success) {
                message.info("Dati spegnimento inviati al Master LED!");
                setIsPrenotato(res.data.isPrenotato);
            } else {
                message.error(res.data.message);
            }

        })
    }

    return (
        <>
            <Modal
                {...modalProps}
                width={'60%'}
                cancelText={'Annulla'} okText={'Presa Visione'} onOk={close} footer={false}>
                <Space>
                    {/*<Button onClick={reloadData}>Aggiorna</Button>*/}
                    <Checkbox onChange={onChange} checked={onlyPosition.current}>Visualizza Esauriti</Checkbox>
                </Space>

                <Table dataSource={dataRestock}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       loading={dataRestock.length === 0}
                >
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Descrizione'}
                        filters={[
                            {
                                text: 'Tabacchi',
                                value: 'isTabacco',
                            },
                            {
                                text: 'Gratta e Vinci',
                                value: 'isGeneric',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            console.log(record);
                            return record?.product?.category[value];
                        }}
                        />
                    <Table.Column
                        dataIndex={'qtaRiassortimento'}
                        key={'qtaRiassortimento'}
                        title={'Qtà'}
                    />
                    <Table.Column
                        dataIndex={'position'}
                        key={'position'}
                        title={'Posizione'}
                        render={(value) => (
                            <TagField value={value ? value : 'ESAURITO'} color={value ? 'green' : 'red'} />
                        )}
                    />
                </Table>
            </Modal>
            <Space>
                <Badge className={(dataRestock.filter((x: any) => x.position !== null).length) > 0 ? 'checkEspositore' : ''} count={dataRestock.filter((x: any) => x.position !== null).length} size="default" color={'green'}>
                    <Button
                        icon={<Icons.BellOutlined />}
                        shape="circle"
                        size="middle"
                        onClick={showModal} style={{ cursor: 'pointer'}}/>
                </Badge>
                <div hidden={!store.isLuminaria}>
                    <Tooltip title={'Controllo LED Magazzino'}>
                        <Button
                            icon={<Icons.BulbOutlined />}
                            shape="circle"
                            size="middle"
                            onClick={accendiLuminaria} style={{ marginLeft: 10, cursor: 'pointer', background: '#4a00a1'}}/>
                    </Tooltip>
                    <Tooltip title={'Spegnimento LED Magazzino'}>
                        <Button
                            icon={<Icons.PoweroffOutlined />}
                            shape="circle"
                            size="middle"
                            className={isPrenotato ? "blink_me_red" : ''}
                            onClick={spegniLuminaria} style={{ marginLeft: 10, cursor: 'pointer', background: '#4a00a1'}}/>
                    </Tooltip>
                </div>


            </Space>

        </>
    );
};
