import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import {Checkbox, Col, Form, Input, Row, Select, Switch} from 'antd';
import React, {useContext} from 'react';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {IEspositori, ISeller} from '../../interfaces';


export const EspositoriCreate: React.FC<IResourceComponentsProps> = () => {
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const {formProps, saveButtonProps} = useForm<IEspositori>({
        warnWhenUnsavedChanges: false
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const save = useCreate<IEspositori>();

    const onFinish = (value: any) => {
        // console.log(value)
        utilityHelper.permission().then( r => {
            // console.log(r);
            if (r !== 'admin') {
                value.seller = store.id;
            }
            // console.log(value);
            save.mutateAsync({
                resource: 'espositoris',
                values: value
            }).then( res => {
                navigate('/prm/espositoris');
            })
        })

    }

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Description"
                                name="descrizione"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8} hidden={store?.id}>
                            <Form.Item
                                label="Seller"
                                name={["seller", "id"]}
                                rules={[
                                    {
                                        required: !store?.id,
                                    },
                                ]}
                            >
                                <Select {...sellerSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Default?"
                                name="isDefault"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Checkbox/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
