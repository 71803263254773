import {
    CrudFilters,
    IResourceComponentsProps, useCreate, useCustomMutation, useExport, useList, useNotification,
    useTranslate,
} from '@refinedev/core';
import {
    DeleteButton,
    EditButton,
    List,
    ShowButton, useModal,
    useModalForm, useSelect,
    useTable
} from '@refinedev/antd';
import {
    Button,
    Col,
    Form,
    Input,
    InputRef, message,
    Modal, Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Switch,
    Table
} from 'antd';
import {ICategory, IProduct, IRestock, IStock} from 'interfaces';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import {TurnContext} from '../../contexts/TurnContext';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';


export const ReferenzeStock: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value, value2, value4} = useContext(StoreContext);
    const [turn, setTurn] = useContext(TurnContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category, giacenze_espositorises, giacenze_espositorises.espositoris'},

        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        pagination: {
            pageSize: 15,
            mode: "server"
        },
        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const navigate = useNavigate();

    const {data: aggiornamentoMagazzino, refetch: updateGiacenze, isSuccess} = useList<any>({
        resource: "custom/updateStockGiacenzes",
        queryOptions: {
            enabled: false
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    useEffect(() => {
        updateGiacenze().then();
    }, []);


    const createAlertRestock = useCreate();
    const oldValue: any = useRef([]);
    const valueChange = (nuovo: any) => {
        console.log(nuovo);
        oldValue.current.push({
            scansiaPz: editFormProps.form?.getFieldValue('espositore'),
            scansiaStock: editFormProps.form?.getFieldValue('scansiaStock'),
            aggio: editFormProps.form?.getFieldValue('aggio'),
        })
    }
    const handleSubmit = (e: any) => {
        console.log('Nuovi Valori: ', e);
        e.isVariazione = true;
        e.idEspositore = espositore;
        e.espositore = Number(e.espositore);
        console.log('Old Value; ', oldValue.current);
        onFinish(e).then( (res: any) => {
            createAlertRestock.mutateAsync({
                resource: 'alert-restocks',
                successNotification: false,
                values: {
                    espositori: espositore,
                    description: 'Variazione Referenza',
                    isAlert: true,
                    isApprovato: false,
                    seller: store.id,
                    turn: turn?.id,
                    stock: id,
                    note: 'Delta Valori: ' + ' ScansiaPz: ' + (e.espositore - oldValue.current[0].scansiaPz) + ' ScansiaStock: ' + (e.scansiaStock - oldValue.current[0].scansiaStock) + ' Aggio: ' + (e.aggio - oldValue.current[0].aggio)
                }
            }).then(() => {
                console.log('Alert Restock Creato Correttamente');
                oldValue.current = [];
            })
        });
        // close();
        close()
        navigate('/stk/referenze');
    }

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        close,
        submit,
        id,
        onFinish,
        formLoading,
        queryResult: resultForm
    } = useModalForm<IStock>({
        action: "edit",
        resource: "stocks",
        redirect: false,
        // onMutationSuccess: ((e: any) => { handleSubmit(e) }),
        meta: {populate: 'giacenze_espositorises, giacenze_espositorises.espositoris, product, seller'},
        warnWhenUnsavedChanges: false,
    });

    const [form] = Form.useForm();

    useEffect(() => {
        if (resultForm && resultForm.data && resultForm.data.data) {
            const g: any = resultForm?.data?.data?.giacenze_espositorises.find((x: any) => x.espositoris.id === espositore);
            editFormProps.form?.setFieldValue('espositore', g.giacenza)
        }
    }, [formLoading]);

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 999999,
            mode: "server"
        }
    });

    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    const { show, close: closeModal, modalProps } = useModal();
    const createStock = useCreate<IStock>();
    const [tempArticolo, setTempArticolo] = useState<any>([]);
    const codice = useRef('');

    const {tableQueryResult: getStocks} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},
        queryOptions: {
          enabled: false
        },
        pagination: {
            pageSize: 15,
            mode: "server"
        },
        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "product][codice]",
                    operator: "eq",
                    value: codice.current
                }
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const saveAddArticolo = () => {
        tempArticolo.map((art: any) => {
            console.log("CODICE:", art)
            codice.current = art.codice;
            // console.log("ID: ", art.id)
            // console.log("TABLE: ", tableQueryResult)
            setTimeout(() => {
                getStocks.refetch().then((a: any) => {
                    if (a.data && a.data.data && a.data.data.length > 0) {
                        message.error("Articolo già presente in Stock!")
                    } else {
                        createStock.mutateAsync({
                            resource: 'custom/createNewStock',
                            successNotification: false,
                            values: {
                                seller: store.id,
                                stock: {
                                    description: art.description,
                                    price: art.price,
                                    stockMinKg: (art?.category?.isGeneric) ? 1 : art.stockMinKg,
                                    aggio: (art?.category?.isGeneric) ? 8 : 0,
                                    nrPezziStecca: art.nrPezziStecca,
                                    pesoFiscaleConf: art.pesoFiscaleConf,
                                    product: art.id,
                                }
                            }
                        }).then( (res: any) => {
                            tableQueryResult.refetch();
                        })
                    }
                })
            }, 500)
            // console.log('YYY: ', idArticolo);
        })
        setTempArticolo([]);
        closeModal();
    }

    const handleFocus = (event: any) => {
        event.target.select();
    };

    const [tempSearch, setTempSearch] = useState<any>();
    const [textSearch, setTextSearch] = useState<any>();

    const { selectProps, queryResult: productResult } = useSelect<IProduct>({
        resource: "products",
        optionLabel: "description",
        optionValue: "id",

        meta: {
            populate: "barcodes, barcode_packages, category"
        },

        queryOptions: {
            enabled: false
        },
        filters: [
            {
                operator: "or",
                value: [
                    {
                        field: "barcodes][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "barcode_packages][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "description]",
                        operator: "contains",
                        value: textSearch
                    }
                ]
            },
        ],

        pagination: {
            pageSize: 9999,
            mode: "server"
        },
    });

    const { open } = useNotification();
    const handleSearch = (e: any) => {
        setTextSearch(tempSearch);
        setTimeout(() => {
            productResult.refetch().then( (res: any) => {
                if (res && res.data && res.data.data.length === 1) {
                    // console.log(res.data.data);
                    const checkExists = tempArticolo.findIndex((x: any) => x.id === res.data?.data[0]?.id);
                    if (checkExists === -1) {
                        // console.log(checkExists);
                        // console.log(tempArticolo);
                        res.data.data[0].key = new Date().getTime();
                        //tempArticolo.sort((a: any, b: any) => Number(a.key) > Number(b.key));
                        setTempArticolo([...tempArticolo, res.data?.data[0]])
                    } else {
                        open?.({
                            type: "error",
                            message: "Articolo già presente nella lista",
                            description: "Attenzione!",
                        });
                    }

                }
            })
            setTempSearch('')
        }, 500)
    };

    const delTempArticolo = (record: any) => {
        // console.log(record, tempArticolo);
        const newIndex = tempArticolo.findIndex((i: any) => i.codice === record.codice);
        tempArticolo.splice(newIndex, 1);
        setTempArticolo([...tempArticolo]);
    }

    const [allineamento, setAllineamento] = useState(false);
    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = () => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === passwordSeller) {
                    localStorage.setItem('pass', inputRef.current.input.value);
                    setAllineamento(true);
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleAllineamento = () => {
        if (localStorage.getItem('pass') === passwordSeller) {
            setAllineamento(true);
        } else {
            showConfirm()
        }
    }
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const [passwordSeller, setPasswordSeller] = useState('');
    useEffect(() => {
        utilityHelper.seller().then( (res: any) => {
            setPasswordSeller(res.password);
        });
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const { data: printStock, refetch } = useList<IStock>({
        resource: 'stocks',
        meta: {populate: 'seller, product, product.category, giacenze_espositorises, giacenze_espositorises.espositoris'},
        queryOptions: {
          enabled: false
        },
        pagination: {
          pageSize: 9999999,
          mode: 'server'
        },
        filters: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ],
        sorters: [
            {
                field: 'product.category.description',
                order: 'asc'
            },
            {
                field: 'description',
                order: 'asc'
            },
        ]
    });

    const print = () => {
        setIsLoading(true);
        let data: any = [];
        refetch().then((res: any) => {
            // console.log(res);
            res.data.data.map((r: any) => {
                const g = r.giacenze_espositorises.find((x: any) => x.espositoris.id === espositore);
                data.push({
                    codice: r.product.codice,
                    descrizione: r.description,
                    categoria: r.product.category.description,
                    prezzo: r.product.price,
                    magazzino: r.giacenza,
                    espositore: g.giacenza,
                    minEspositore: r.scansiaStock,
                    stockMinimo: r.stockMinKg,
                    aggio: r.aggio,
                    pzConfezione: r.nrPezziStecca,
                    pesoFiscale: r.pesoFiscaleConf

                })
            })
            // console.log(data);
            setIsLoading(false);
            setTimeout(() => {
                let ws = XLSX.utils.json_to_sheet(data);
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "sheet");
                let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
                let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
                XLSX.writeFile(wb, 'Referenze_' + dayjs().format('DD_MM_YYYY') + '.xlsx');
            }, 500)
        })
    }

    const [forceEnable, setForceEnable] = useState(false);

    const handleEsc = (event: any) => {
        if (event.key === 'z') {
            setForceEnable(!forceEnable);
        }
    };

    const getRestock = useCustomMutation();
    const forzaRiassortimento = (id: any) => {
        console.log(id);
        getRestock.mutateAsync({
            method: 'post',
            url: API_URL + '/api/custom/forzaRiassortimento',
            values: {
                seller: store.id,
                stockId: id,
                espositore: espositore
            }
        }).then(res => {
            console.log(res);
            message.info(res.data ? 'Richiesta Eseguita Correttamente' : 'Non disponibile in magazzino');
        })
    }

    return (
        <List
            title={'Referenze'}
            headerProps={{
                extra: [
                    <>
                        <Row>
                            <Col style={{ marginRight: 10}}>
                                <Button type={'primary'} style={{
                                    backgroundColor: 'green'
                                }} onClick={show}>Nuovo Articolo</Button>
                            </Col>
                            <Col style={{ marginRight: 10}}>
                                <Button type={'primary'} style={{
                                    backgroundColor: 'blue'
                                }} onClick={handleAllineamento}>Variazione</Button>
                            </Col>
                            <Col style={{ marginRight: 10}}>
                                <Button loading={isLoading} type={'primary'} style={{
                                    backgroundColor: 'grey'
                                }} onClick={print}>Stampa</Button>
                            </Col>
                            <Col style={{ marginRight: 10}} >
                                <Popover
                                    placement="leftTop"
                                    style={{
                                        float: "right"
                                    }}
                                    content={
                                        <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                              wrapperCol={{ span: 16 }}>
                                            <Form.Item label="Cerca" name="description">
                                                <Input
                                                    allowClear={true}
                                                    placeholder="Cerca"
                                                    prefix={<Icons.SearchOutlined />}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Categoria" name="category">
                                                <Select
                                                    {...categorySelectProps}
                                                    allowClear={true}
                                                    placeholder="Categoria"

                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Space>
                                                    <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                        Filter
                                                    </Button>
                                                    <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                        Reset
                                                    </Button>
                                                </Space>

                                            </Form.Item>
                                        </Form>}
                                    title="Custom Filter"
                                    trigger="click"
                                    open={visible}
                                    onOpenChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{marginBottom: 15}} >Filtro</Button>
                                </Popover>
                            </Col>
                        </Row>
                    </>
                ],
            }}
        >
                <Table size="small" {...tableProps}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       // scroll={{y: window.innerHeight - 293 + 'px'}}
                       // pagination={{
                       //     ...tableProps.pagination,
                       //     pageSize: 15,
                       //     showSizeChanger: true,
                       // }}
                       rowClassName={(record, index) => (record.isRadiato ? "red" : "")}
                >
                    <Table.Column
                        dataIndex="product"
                        key="product.codice"
                        title="Codice"
                        render={(value) => value?.codice}
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.product', 'Descrizione')}
                        render={(value) => value}
                    />

                        <Table.Column
                            dataIndex="product"
                            key="product"
                            title={translate('pages.stock.price', 'Prezzo')}
                            render={(value) => (
                                Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                }) + " €"
                            )}
                        />

                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    onClick={() => editModalShow(record.id)}
                                />
                                <Popconfirm title={'Sei Sicuro di Voler Forzare il Riassortimento?'}
                                            onConfirm={() => forzaRiassortimento(record.id)}
                                            cancelText={'No'}
                                            okText={'Si'}
                                >
                                    <Button
                                        size="small"
                                        icon={<Icons.BellOutlined />}
                                    />
                                </Popconfirm>
                                <EditButton hidden={!allineamento}
                                            hideText
                                            size="small"
                                            onClick={() => editModalShow(record.id)}
                                            recordItemId={record.id}
                                />
                                <DeleteButton hidden={!allineamento}
                                              mutationMode={'undoable'}
                                              hideText
                                              size="small"
                                              recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />
                </Table>
            <Modal {...editModalProps}
                    cancelText={'Annulla'}
                   okText={'Salva'}
                   title={'Dettaglio'}
                   footer={!allineamento ? <></> : [
                       <Button key="submit" type="primary" onClick={editFormProps.form?.submit}>
                           Submit
                       </Button>,
                   ]}
            >
                <Form {...editFormProps} layout="vertical" onFinish={handleSubmit} onClick={(e: any) => {valueChange(e.target.value)}}>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Prodotto"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento} />
                            </Form.Item>
                        </Col>
                        <Col span={4} hidden={true}>
                            <Form.Item
                                label="Qta"
                                name="qta"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="giacenza"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Espositore"
                                name="espositore"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento} onKeyPress={(event) => {
                                    handleEsc(event);
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4} hidden={true}>
                            <Form.Item
                                label="Scansia N.ro"
                                name="scansiaNr"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Min. Espositore"
                                name="scansiaStock"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!forceEnable} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!forceEnable}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Pezzi Riassortimento"
                                name="pezziRiassortimento"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={!allineamento}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Radiato"
                                name="isRadiato"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch disabled={!allineamento}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal {...modalProps}
                   width={'40%'}
                   title={'Inserimento Articolo'}
                   cancelText={'Annulla'}
                   okText={'Conferma'}
                   onOk={saveAddArticolo}>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: 20}}>
                        <Input style={{
                            width: "100%"
                        }}
                               placeholder={"Codice a barre..."}
                               autoFocus={true}
                               onFocus={(e) => handleFocus(e)}
                               onChange={event => setTempSearch(event.target.value)}
                               onPressEnter={(e) => handleSearch(e)}
                               allowClear={true}
                               value={tempSearch}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table dataSource={tempArticolo}
                               size={'small'}
                               scroll={{y: '300px'}}
                               pagination={false}>
                            <Table.Column
                                dataIndex={'description'}
                                key={'description'}
                                title={'Articolo'}
                                sorter={(a: any, b: any) => {
                                    return Number(a.key) - Number(b.key)}}
                                defaultSortOrder={'descend'}
                            />
                            <Table.Column
                                dataIndex={'codice'}
                                key={'codice'}
                                title={'Codice'}
                            />
                            <Table.Column
                                dataIndex={'actions'}
                                key={'actions'}
                                title={'Azioni'}
                                render={(_, record, index) => (
                                    <Space>
                                        <Button
                                            icon={<Icons.DeleteOutlined />}
                                            size="small"
                                            onClick={() => delTempArticolo(record)}
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </Modal>
        </List>

    );
};
