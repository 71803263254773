import {CrudFilters, IResourceComponentsProps, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, DatePicker, Form, Input, Popover, Row, Select, Space, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import dayjs from 'dayjs';

export const ReportMovimentazioniList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const [form] = Form.useForm();
    const {tableProps, searchFormProps, tableQueryResult} = useTable<any>({
        resource: "custom/reportBarcodes",
        pagination: {
            pageSize: 9999999,
            mode: "server"
        },
        queryOptions: {
          enabled: true
        },
        onSearch: (params: any) => {
            console.log(params);
            const filters: CrudFilters = [];
            filters.push(
                {
                    field: 'barcode',
                    operator: 'eq',
                    value: (params?.description) ? params?.description : '0'
                },
                {
                    field: 'daData',
                    operator: 'eq',
                    value: (params?.daData) ? dayjs(params?.daData).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
                },
                {
                    field: 'giacenza',
                    operator: 'eq',
                    value: (params?.giacenza) ? params?.giacenza : 0,
                }
            );
            // tableQueryResult.refetch();
            return filters;
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'espositore',
                    operator: 'eq',
                    value: espositore
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    return (
            <List
                title={'Report Barcode'}
                headerProps={{
                    extra: [
                        // <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{span: 8}}
                        //       wrapperCol={{span: 24}}>
                        //     <Row gutter={24}>
                        //         <Col span={4}>
                        //             <Form.Item label="Barcode" name="description">
                        //                 <Input
                        //                     onPressEnter={form.submit}
                        //                     allowClear={true}
                        //                     placeholder="Cerca"
                        //                     prefix={<Icons.SearchOutlined/>}
                        //                 />
                        //             </Form.Item>
                        //         </Col>
                        //         <Col span={4}>
                        //             <Form.Item label={'Data'} name={'daData'}>
                        //                 <DatePicker
                        //                     style={{width: '100%'}}
                        //                     format={'DD-MM-YYYY'}
                        //                     placeholder={'da data...'}
                        //                 />
                        //             </Form.Item>
                        //         </Col>
                        //         <Col span={4}>
                        //             <Form.Item label={'Giacenza'} name={'giacenza'}>
                        //                 <Input
                        //                     onPressEnter={form.submit}
                        //                     allowClear={true}
                        //                     placeholder="Giacenza"
                        //                 />
                        //             </Form.Item>
                        //         </Col>
                        //     </Row>
                        // </Form>
                    ],
                }}
            >
                <hr />
                <Form form={form} layout="horizontal" {...searchFormProps}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label="Barcode" name="description">
                            <Input
                                onPressEnter={form.submit}
                                allowClear={true}
                                placeholder="Cerca"
                                prefix={<Icons.SearchOutlined/>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={'Data'} name={'daData'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'da data...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={'Giacenza'} name={'giacenza'}>
                            <Input
                                onPressEnter={form.submit}
                                allowClear={true}
                                placeholder="Giacenza"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" htmlType="submit" >Conferma</Button>
                    </Col>
                </Row>
                </Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableProps}
                            size={'small'}
                               scroll={{y: window.innerHeight - 330 + 'px'}}
                               pagination={false}
                        >
                            <Table.Column
                                dataIndex={'id'}
                                title={'ID'}
                                key={'id'}
                            />
                            <Table.Column
                                dataIndex={'codice'}
                                title={'AMS'}
                                key={'codice'}
                            />
                            <Table.Column
                                dataIndex={'description'}
                                title={'Descrizione'}
                                key={'description'}
                            />
                            <Table.Column
                                dataIndex={'position'}
                                title={'Posizione'}
                                key={'position'}
                            />
                            <Table.Column
                                dataIndex={'doc_number'}
                                title={'Numero Documento'}
                                key={'doc_number'}
                            />
                            <Table.Column
                                dataIndex={'created_at'}
                                key={'created_at'}
                                title={'Data'}
                                render={(value, record: any) => (
                                    <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                                )}
                            />
                            <Table.Column
                                dataIndex={'tipo'}
                                key={'tipo'}
                                title={'Tipo'}
                                render={(value, record: any) => (
                                    <TagField value={record.is_deleted ? 'VENDITA CANCELLATA' : value} style={{
                                        color: (value === 'VENDITA' && record.is_deleted) ? 'violet' : value === 'CARICO DDT' ? 'gray' : value === 'RIASSORTIMENTO ANTICIPATO' ? 'red' : value === 'RIASSORTIMENTO' ? 'cornflowerblue' : value === 'VARIAZIONE REFERENZA' ? 'yellow' : 'green'
                                    }} />
                                )}
                            />
                            <Table.Column
                                dataIndex={'progressivo'}
                                key={'progressivo'}
                                title={'Progressivo'}
                                render={(value, record: any) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'giacenza'}
                                key={'giacenza'}
                                title={'Giacenza'}
                                render={(value, record: any) => {

                                    return(
                                        Number(value)
                                    )
                                }}
                            />
                        </Table>
                    </Col>
                </Row>
            </List>
    );
};
