import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col } from "antd";

import {IEspositori, IGroup, ISeller, IUser} from 'interfaces';
import {store} from '@refinedev/cli/dist/update-notifier';

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<IUser>({
        meta: {populate: ["group, espositore"]},
    });

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.group?.id,

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: espositoreSelectProps, queryResult: listEspositori } = useSelect<IEspositori>({
        resource: "espositoris",
        optionValue: 'id',
        optionLabel: 'descrizione',
        defaultValue: queryResult?.data?.data?.espositore?.id,
        // filters: [
        //     {
        //         field: 'seller',
        //         operator: 'eq',
        //         value: store.id
        //     }
        // ],
        pagination: {
            mode: "server"
        }
    });

    const setEspositore = (esp: any) => {
        // console.log(esp);
        // console.log(listEspositori.data?.data.find((x: any) => x.id === esp));
        const e: any = listEspositori.data?.data.find((x: any) => x.id === esp);
        formProps.form?.setFieldValue('idEspositore', esp);
        formProps.form?.setFieldValue('descrizioneEspositore', e.descrizione);
    }

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={4}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cognome"
                            name="surname"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telegram ID"
                            name="telegramId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item hidden={true}
                            label="Espositore"
                            name="idEspositore"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label="Espositore"
                                   name="descrizioneEspositore"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Espositore"
                            name={["espositore", "id"]}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...espositoreSelectProps} onSelect={(e: any) => setEspositore(e)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={6}>
                        <Form.Item
                            label="UserName"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Stato Utente"
                            name="confirmed"
                        >
                            <Select
                                options={[
                                    {
                                        label: "ABILITATO",
                                        value: true,
                                    },
                                    {
                                        label: "DISABILITATO",
                                        value: false,
                                    },
                                ]}
                                defaultValue={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Group"
                            name={["group", "id"]}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...groupSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
