import {CrudFilters, IResourceComponentsProps, useList, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, DatePicker, Form, Input, Popover, Row, Select, Space, Table, Typography} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {IStock} from '../../interfaces';
const { Title } = Typography;

export const ReportSnapshotList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositori, setEspositori] = value4;
    const [form] = Form.useForm();
    const resultStock = useList<IStock>({
        resource: "stocks",
        meta: {
            populate: 'seller, product, product.category, giacenze_espositorises, giacenze_espositorises.espositoris'
        },
        pagination: {
            pageSize: 999,
            mode: "server"
        },
        queryOptions: {
            enabled: true
        },
        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
        ],
        sorters: [
            {
                field: 'product.description',
                order: 'asc'
            }
        ]
    });

    const updateTot = async (data: any) => {
        // GROUP BY CATEGORY
        const tempGroup: any = data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})



        // data.map((r: any) => {
        //     console.log(r);
        //     if (r.scansiaPz > 0) {
        //         productEspositore.push({codice: r.product?.codice, description: r.description, qta: r.scansiaPz})
        //     }
        //     if (r.qta > 0) {
        //         productMagazzino.push({codice: r.product?.codice, description: r.description, qta: r.qta})
        //     }
        // });

        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];


        for (let g in tempGroup) {
            let productMagazzino: any = [];
            let productEspositore: any = [];
            tempGroup[g].map((r: any) => {
                const gc = r.giacenze_espositorises.find((x: any) => x.espositoris.id === espositori);
                // console.log(gc);
                if (gc?.giacenza > 0) {
                    productEspositore.push({codice: r.product?.codice, category: r.description, qta: gc?.giacenza, tot: gc?.giacenza * r.product.price})
                }
                if (r.qta > 0) {
                    productMagazzino.push({codice: r.product?.codice, category: r.description, qta: r.qta, tot: (r.qta * (r.nrPezziStecca * r.product.price))})
                }
            })

            priceCategoryEspositore.push({
                id: Math.random(),
                qta: Number(tempGroup[g].map((item: any) => item.giacenze_espositorises.find((x: any) => x.espositoris.id === espositori)?.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                children: productEspositore,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item.giacenze_espositorises.find((x: any) => x.espositoris.id === espositori)?.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                id: Math.random(),
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                qtaP: Number(tempGroup[g].map((item: any) => (item.giacenza * item.nrPezziStecca)).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                children: productMagazzino,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        return {priceCategoryEspositore, priceCategoryMagazzino};
    }

    const [espositore, setEspositore] = useState([]);
    const [magazzino, setMagazzino] = useState([]);

    useEffect(() => {
        resultStock.refetch().then((resStock: any) => {
            if (resStock && resStock.data && resStock.data.data && resStock.data.data.length > 0) {
                updateTot(resStock.data.data).then((resTotalStock: any) => {
                    setEspositore(resTotalStock['priceCategoryEspositore']);
                    setMagazzino(resTotalStock['priceCategoryMagazzino']);
                })
            }
        })
    }, [])
    return (
            <List
                title={'Report Snapshot'}
            >
                <hr />
                <Row gutter={24}>
                    <Col span={24}>
                        <Table dataSource={espositore}
                               title={data => (<Title level={5}>Espositore</Title> )}
                               size={'small'}
                               rowKey="id"
                               sticky={false}
                               pagination={false}>
                            <Table.Column
                                dataIndex={'category'}
                                key={Math.random()}
                                title={'Categoria'}
                                render={(value) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'qta'}
                                key={'qta'}
                                title={'Qta'}
                                render={(value) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'tot'}
                                key={'tot'}
                                title={'Totale'}
                                render={(value) => (
                                    '€ ' + parseFloat(value).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    })
                                )}
                            />
                        </Table>
                        <Table dataSource={magazzino}
                               title={data => (<Title level={5}>Magazzino</Title> )}
                               size={'small'}
                               sticky={false}
                               rowKey="id"
                               pagination={false}>
                            <Table.Column
                                dataIndex={'category'}
                                key={'category'}
                                title={'Categoria'}
                                render={(value) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'qta'}
                                key={'qta'}
                                title={'Qta'}
                                render={(value) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'qtaP'}
                                key={'qtaP'}
                                title={'Qta P'}
                                render={(value) => (
                                    value
                                )}
                            />
                            <Table.Column
                                dataIndex={'tot'}
                                key={'tot'}
                                title={'Totale'}
                                render={(value) => (
                                    '€ ' + parseFloat(value).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    })
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </List>
    );
};
