import {IResourceComponentsProps, useCreate, useList, useTranslate} from '@refinedev/core';
import {List, useForm, useSelect, useTable} from '@refinedev/antd';
import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, message, Popconfirm, Row, Select} from 'antd';
export const CreateStock: React.FC<IResourceComponentsProps> = () => {
    const {form} = useForm();
    const translate = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const {selectProps: sellerProps} = useSelect({
        resource: 'sellers',
        optionLabel: 'description',
        optionValue: 'id'
    });

    const createStock = useCreate<any>()
    const onFinish = (values: any) => {
        console.log('Success:', values);
        setLoading(true);
        if (values.fromSellerId && values.toSellerId) {
            createStock.mutateAsync({
                successNotification: false,
                resource: 'custom/createDefaultStock',
                values: {
                    fromSellerId: values.fromSellerId,
                    toSellerId: values.toSellerId
                }
            }).then( (res: any) => {
                if (res && res.data && res.data.respone) {
                    message.info(res.data.message);
                } else {
                    message.error(res.data.message);
                }
                setLoading(false);
            })
        } else {
            message.info('Si è verificato un Errore!');
        }
    };

    const confirm = () => {
        form.submit();
    };

    return (
        <>
            <List title={'Create Default Stock'}>
                <Form form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label={'Seleziona Sorgente Dati'}
                                name={'fromSellerId'}
                                rules={[{
                                    required: true,
                                    message: 'Selezionare il Punto Vendita Sorgente' }]}
                                >
                                <Select {...sellerProps} style={{ width: '100%'}} />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={'Seleziona Destinazione Dati'}
                                name={'toSellerId'}
                                rules={[{
                                    required: true,
                                    message: 'Selezionare il Punto Vendita Destinazione' }]}
                            >
                                <Select {...sellerProps} style={{ width: '100%'}} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Popconfirm
                                placement="top"
                                title={'Sei Sicuro di voler procedere? L\'operazione è irreversibile!'}
                                description={<>
                                    <span>
                                        Questa procedura creerà nel Punto Vendita di Destinzaione le seguenti tabelle:
                                    <br />1) Stock di Default
                                    <br />2) Tipi Documento
                                    <br />3) Causali
                                    <br />4) Progressivi
                                    <br />!!!ATTENZIONE - La Procedura va eseguita solo una volta per lo Stesso Punto Vendita!
                                    </span>
                                </>}
                                onConfirm={confirm}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={loading} type={'primary'} htmlType={'button'}>Conferma</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Form>
            </List>
        </>
    );
};
