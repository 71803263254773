import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act
[policy_definition]
p = sub, obj, act, eft
[role_definition]
g = _, _
[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))
[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, admin, dashboard, (list)
p, admin, users, (list)|(edit)|(create)|(delete)
p, admin, products, (list)|(edit)|(create)|(delete)
p, admin, sellers, (list)|(edit)|(create)|(delete)
p, admin, devices, (list)|(edit)|(create)|(delete)
p, admin, status-ware-houses, (list)|(edit)|(create)|(delete)
p, admin, groups, (list)|(edit)|(create)|(delete)
p, admin, device-types, (list)|(edit)|(create)|(delete)
p, admin, espositoris, (list)|(edit)|(create)|(delete)
p, admin, categories, (list)|(edit)|(create)|(delete)
p, admin, default-stocks, (list)|(edit)|(create)|(delete)
p, admin, esl-settings, (list)|(edit)|(create)|(delete)
p, admin, verypaperadmin, (show)|(list)|(edit)|(create)|(delete)
p, user, dashboard, (list)
p, user, causals, (list)|(edit)|(create)|(delete)
p, user, turns, (list)|(edit)|(create)|(delete)|(show)
p, user, magazzino
p, user, allineamento
p, user, stocks, (list)|(edit)|(create)|(show)
p, user, whare-house-movements, (show)|(list)|(edit)|(create)|(delete)
p, user, whare-house-movement-rows, (show)|(list)|(edit)|(create)|(delete)
p, user, doc-types, (show)|(list)|(edit)|(create)|(delete)
p, user, prm, (show)|(list)|(edit)|(create)|(delete)
p, user, parameters, (show)|(list)|(edit)|(create)|(delete)
p, user, parameters/*, (create)
p, user, rdr, (show)|(list)|(edit)|(create)|(delete)
p, user, stk, (show)|(list)|(edit)|(create)|(delete)
p, user, referenze, (show)|(list)|(edit)|(create)|(delete)
p, user, magazzino, (show)|(list)|(edit)|(create)|(delete)
p, user, espositore, (show)|(list)|(edit)|(create)|(delete)
p, user, verypaper, (show)|(list)|(edit)|(create)|(delete)
p, user, riordinostock, (show)|(list)|(edit)|(create)|(delete)
p, user, riordinoscientifico, (show)|(list)|(edit)|(create)|(delete)
p, user, riordinoluminaria, (show)|(list)|(edit)|(create)|(delete)
p, user, riordinomagazzino, (show)|(list)|(edit)|(create)|(delete)
p, user, alert-restocks, (show)|(list)|(edit)|(create)|(delete)
p, user, check-restocks, (show)|(list)|(edit)|(create)|(delete)
p, user, venditas, (list)|(delete)
p, user, ptn, (show)|(list)|(edit)|(create)|(delete)
p, user, patents, (show)|(list)|(edit)|(create)|(delete)
p, user, patent-movements, (show)|(list)|(edit)|(create)|(delete)
p, user, sale-movements, (delete)
p, user, reports, (list)
p, user, report-gevs, (list)
p, user, snapshot, (list)
p, user, report-movimentazionis, (list)
p, user, report-grafico, (list)
p, user, report-grafico-ulp, (list)
p, user, remote-checks, (list)|(show)
p, operator, dashboard, (list)
p, operator, turns, (list)|(edit)|(create)|(delete)|(show)
p, operator, reports, (list)|(edit)|(create)|(delete)
p, operator, report-gevs, (list)
p, operator, report-movimentazionis, (list)
p, operator, report-grafico, (list)
p, operator, report-grafico-ulp, (list)
p, operator, remote-checks, (create)|(list)|(show)|(edit)|(delete)
`);
