import { IResourceComponentsProps, useExport, useList, useTranslate, useUpdate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DateField,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Input, Space, Switch, Table, Tooltip} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {IPatentMovement, IPatentMovementRow, IWhareHouseMovement} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {useNavigate} from 'react-router-dom';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

function loadFile(url: any, callback: any) {
    PizZipUtils.getBinaryContent(url, callback);
}

export const PatentMovementList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const translate = useTranslate();
    const navigate = useNavigate();
    const {tableProps, tableQueryResult} = useTable<IPatentMovement>({
        resource: 'patent-movements',
        meta: {populate: '*'},
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const [idMov, setIdMov] = useState();
    const getRow = useList<IPatentMovementRow>({
        resource: 'patent-movement-rows',
        meta: {populate: '*'},
        queryOptions: {
            enabled: false
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'patent_movement][id]',
                operator: 'eq',
                value: idMov
            },
        ],
        pagination: {
            pageSize: 99999
        },
        sorters: [
            {
                field: 'id',
                order: 'desc'
            }
        ]
    })

    const {triggerExport, isLoading: exportLoading} = useExport<IPatentMovement>();
    const updatePatentMovement = useUpdate<IPatentMovement>();
    const toggle = (id: string, value: boolean) => {
        // console.log(id, value);
        updatePatentMovement.mutate({
            resource: 'patent-movements',
            id: id,
            values: {
                isPatent: false,
            }
        })
    };
    const generateDocument = (dati: any) => {
        setIdMov(dati.id);
        let prodotti: any = [];
        loadFile(
            '/template/U88_PAT.docx',
            function (error: any, content: any) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                getRow.refetch().then( res => {
                    console.log(res);
                    if (res && res.isSuccess && res.data && res.data.data.length > 0) {
                        const totKg = Number(res.data.data.map((pro) => pro.stock.pesoFiscaleConf).reduce((acc, curr) => acc + curr)).toFixed(2);
                        const impL: any = Number(res.data.data.map((pro) => pro.price).reduce((acc, curr) => acc + curr)).toFixed(2);
                        const aggio: any = Number((impL / 100 ) * dati.patent.aggio).toFixed(2);
                        let tempProd: any = [];
                        res.data?.data.reduce((r: any, a: any) => {
                            if (!r[a.product.id]) {
                                r[a.product.id] = {
                                    prodotto: a.product.description,
                                    kg: 0,
                                    gr: 0,
                                    importo: 0,
                                    qta: 0,
                                    peso: a.stock.pesoFiscaleConf,
                                    price: a.price
                                };
                                tempProd.push(r[a.product.id])
                            }
                            r[a.product.id].qta += 1;
                            //r[a.product.id].importo = a.price * (a.qta + 1);
                            //r[a.product.id].kg = ((a.qta + 1) * a.stock.pesoFiscaleConf < 1 ? 0 : (a.qta + 1) * a.stock.pesoFiscaleConf);
                            //r[a.product.id].gr = ((a.qta + 1) * a.stock.pesoFiscaleConf > 1 ? (a.qta + 1) * a.stock.pesoFiscaleConf : 0);
                            return r;
                        }, {})
                        console.log(tempProd);
                        for (let p of tempProd.sort((a: any, b: any) => a.prodotto.localeCompare(b.prodotto))) {
                            prodotti.push({
                                prodotto: p.prodotto,
                                kg: (p.peso * p.qta >= 1) ? p.peso * p.qta : 0,
                                gr: (p.peso * p.qta <= 1) ? (p.peso * p.qta) * 1000 : 0,
                                importo: parseFloat(Number(p.price * p.qta).toFixed(2)).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                })
                            })
                        }
                        // console.log(prodotti);
                        // console.log("Kgc:", totKg);
                        // console.log("ImportoL:", impL);
                        // console.log("Aggio:", aggio);
                        // console.log("Netto:", impL-aggio);
                        doc.setData({
                            prog: dati.docNumber,
                            qta: totKg,
                            indirizzo2: dati.patent.address,
                            numeroDoc: dati.patent.license,
                            data: dayjs(dati.createdAt).format('DD_MM_YYYY'),
                            nome: dati.patent.company,
                            tipo: 'Patentino',
                            citta2: dati.patent.city,
                            indirizzo: store.address,
                            rivendita: store.storeId,
                            citta: store.city,
                            impLordo: parseFloat(impL).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }),
                            aggi: parseFloat(aggio).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }),
                            impNetto: parseFloat(Number(impL - aggio).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }),
                            dazi: '',
                            impComp: '',
                            prodotti: prodotti
                        });
                        try {
                            doc.render();
                        } catch (error) {
                            throw error;
                        }
                        let out = doc.getZip().generate({
                            type: 'blob',
                            mimeType:
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        }); //Output the document using Data-URI
                        saveAs(out, 'U88_PAT_' + dayjs(dati.createdAt).format('DD_MM_YYYY') + '.docx');
                    }
                })
            }
        );
    };

    return (
        <List
            key={Math.random()}
            title={translate('pages.patentMovement.title', 'Documenti')}
            headerProps={{
                extra: [
                        <CreateButton disabled={(tableQueryResult && tableQueryResult.data) ? tableQueryResult.data?.data.reduce((acc, cur) => cur.isTemporary ? ++acc : acc, 0) > 0 : 0 > 0}/>,
                        // <ExportButton
                        //     onClick={triggerExport}
                        //     loading={exportLoading}
                        // />
                ],
            }}
        >
            <Table size="small" {...tableProps} rowKey="id"
                   sticky={true}
                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column
                    dataIndex="description"
                    key="description"
                    title={translate('pages.patentMovement.description', 'Descrizione')}
                    render={(value, record: IPatentMovement, index) => {
                        return value
                    }}
                />
                <Table.Column
                    dataIndex="doc_type"
                    key="doc_type"
                    title={translate('pages.patentMovement.description', 'Tipo')}
                    render={(value, record: IPatentMovement, index) => {
                        return (value?.description)
                    }}
                />
                <Table.Column
                    dataIndex="DDT Data"
                    key="loadDate"
                    title={translate('pages.patentMovement.loadDate', 'Data')}
                    render={(value, record: IPatentMovement, index) => {
                        return (
                            <DateField value={record.loadDate} format={'DD/MM/YYYY'}></DateField>
                        )
                    }}
                />
                <Table.Column
                    dataIndex="docNumber"
                    key="docNumber"
                    title={translate('pages.patentMovement.docNumber', 'Numero')}
                    render={(value) => (
                        value
                    )}
                />
                <Table.Column
                    dataIndex="total"
                    key="total"
                    title={translate('pages.patentMovement.total', 'Totale')}
                    render={(value) => (
                        Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + ' €'
                    )}
                />
                <Table.Column<{ id: string, isPatent: boolean }>
                    title={translate('pages.patentMovement.action', 'Azioni')}
                    dataIndex="actions"
                    key="actions"
                    render={(_, record) => (
                        <Space>
                            <Tooltip placement={'top'} title={'Visualizza'}>
                            <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                        icon={<Icons.EyeOutlined />}
                            />
                            </Tooltip>
                            <Tooltip placement={'top'} title={'Elimina'}>
                            <DeleteButton mutationMode={'undoable'}
                                          hidden={true}
                                          hideText
                                          size="small"
                                          successNotification={false}
                                          recordItemId={record.id}
                            />
                            </Tooltip>
                            <Tooltip placement={'top'} title={'Sblocca Scarico'}>
                                <Button type="primary" size={'small'} onClick={() => toggle(record.id, record.isPatent)} hidden={!record.isPatent}
                                        icon={record.isPatent ? <Icons.UnlockOutlined /> : <Icons.LockOutlined />}>
                                </Button>
                            </Tooltip>
                            <Tooltip placement={'top'} title={'Stampa U88-PAT'}>
                                <Button type="primary" size={'small'} onClick={() => generateDocument(record)}
                                        icon={<Icons.FilePdfOutlined />}>
                                </Button>
                            </Tooltip>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
