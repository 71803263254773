import {IResourceComponentsProps, useExport, useGetIdentity, useList, useTranslate, useUpdate} from '@refinedev/core';

import {
    DateField, EditButton,
    List,
    TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Input, InputRef, Modal, Space, Switch, Table} from 'antd';
import React, {useContext, useRef} from 'react';
import {IAlertRestock} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {useNavigate} from 'react-router-dom';

export const RestockList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {data: userIdentity} = useGetIdentity<any>();
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;

    const {tableQueryResult: getAlertRestock, tableProps} = useTable<IAlertRestock>({
        resource: 'alert-restocks',
        meta: {
            populate: "seller, turn, stock, stock.product, stock.product.category, turn.user, espositori"
        },

        queryOptions: {
            enabled: true
        },
        filters: {
            permanent:
            [
                {
                    field: "seller][id]",
                    operator: 'eq',
                    value: store?.id
                },
                {
                    field: "isAlert",
                    operator: 'eq',
                    value: true
                },
                {
                    field: "espositori][id]",
                    operator: 'eq',
                    value: espositore
                }
        ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = (id: any) => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === 'Espo2023') {
                    localStorage.setItem('passRestock', inputRef.current.input.value);
                    navigate('/prm/alert-restocks/edit/' + id)
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const navigate = useNavigate();
    const handleAllineamento = (id: any) => {
        if (localStorage.getItem('passRestock') === 'Espo2023') {
            navigate('/prm/alert-restocks/edit/' + id)
        } else {
            showConfirm(id)
        }
    }


    const updateAlertRestock = useUpdate<IAlertRestock>();
    const approva = (record: any, e: any) => {
        updateAlertRestock.mutateAsync({
            resource: 'alert-restocks',
            id: record.id,
            values: {
                isApprovato: e,
                note: e ? 'Approvato' : null
            }
        }).then();
    }

    return (
            <List
                title={translate('pages.group.title', 'Alert Restock')}
            >
                <Table {...tableProps}
                       size={'small'}
                       sticky={false}
                       rowClassName={(record, index) => record.note ? "yellow" : "red"}
                >
                    <Table.Column
                        dataIndex={'stock'}
                        key={'stock'}
                        title={'Articolo'}
                        render={(value) => (
                            value?.product?.codice
                        )}
                    />
                    <Table.Column
                        dataIndex={'stock'}
                        key={'stock.description'}
                        title={'Descrizione'}
                        render={(value) => (
                            value?.product?.description
                        )}
                    />
                    {/*<Table.Column*/}
                    {/*    dataIndex={'position'}*/}
                    {/*    key={'position'}*/}
                    {/*    title={'Posizione'}*/}
                    {/*    render={(value) => (*/}
                    {/*        <TagField value={value} style={{ color: 'red'}}/>*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Motivo'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'note'}
                        key={'note'}
                        title={'Note'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'createdAt'}
                        key={'createdAt'}
                        title={'Data'}
                        render={(value, record: any) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} style={{
                                color: record.note ? 'black' : 'white'
                            }} />
                        )}
                    />
                    <Table.Column
                        dataIndex={'espositori'}
                        key={'espositori'}
                        title={'Espositore'}
                        render={(value) => (
                            value?.descrizione
                        )}
                    />
                    <Table.Column
                        dataIndex={'isApprovato'}
                        key={'isApprovato'}
                        title={'Approva'}
                        render={(value, record: any) => (
                            <Switch  onClick={(e) => approva(record, e)}/>
                        )}
                    />
                    {/*<Table.Column*/}
                    {/*    dataIndex={'createdAt'}*/}
                    {/*    key={'createdAt'}*/}
                    {/*    title={'Data'}*/}
                    {/*    render={(value) => (*/}
                    {/*        <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    onClick={() => {handleAllineamento(record.id)}}
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />
                </Table>
            </List>
    );
};
