import { createContext, useState } from "react";

export const StoreContext = createContext<any>([]);

export const StoreProvider = (props: any) => {
    const [store, setStore] = useState(1);
    const [userPermission, setUserPermission] = useState('');
    const [autoTurn, setAutoTurn] = useState(false);
    const [espositore, setEspositore] = useState(null);

    return <StoreContext.Provider value={{value: [store, setStore], value2: [userPermission, setUserPermission], value3: [autoTurn, setAutoTurn], value4: [espositore, setEspositore]}} {...props} />;
};
