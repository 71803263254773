import React, {FC, useContext, useEffect, useState} from 'react';
import {useGetLocale, useSetLocale, useGetIdentity} from '@refinedev/core';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import {
    Layout as AntdLayout,
    Space,
    Menu,
    Button,
    Dropdown,
    Avatar,
    Typography,
    Switch, theme, Progress, Spin,
} from 'antd';

import {useTranslation} from 'react-i18next';
import {ColorModeContext} from 'contexts';
import {StoreSelect} from '../../select/StoreSelect';
import {DeviceSelect} from '../../select/DeviceSelect';
import {TurnButton} from '../../select/TurnButton';
// It is recommended to use explicit import as seen below to reduce bundle size.
import {RestockButton} from '../../select/RestockButton';

import {StoreContext} from '../../../contexts/StoreContext';
import {SocketSelect} from '../../select/SocketSelect';
import {useCacheBuster} from 'react-cache-buster';
const {DownOutlined} = Icons;
const {Text} = Typography;

interface HeaderProps {
    theme: "light" | "dark";
    setTheme: (theme: "light" | "dark") => void;
}
export const Header: FC<HeaderProps> = (props) => {
    const { checkCacheStatus } = useCacheBuster();

    const {i18n} = useTranslation();
    const { Text } = Typography;
    const { useToken } = theme;
    const { token } = useToken();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value2;
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const {mode, setMode} = useContext(ColorModeContext);

    const currentLocale = locale();

    const menu = (
        <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
            {[...(i18n.languages || [])].sort().map((lang: string) => (
                <Menu.Item
                    key={lang}
                    onClick={() => changeLanguage(lang)}
                    icon={
                        <span style={{marginRight: 8}}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`}/>
            </span>
                    }
                >
                    {lang === 'en' ? 'English' : 'German'}
                </Menu.Item>
            ))}
        </Menu>
    );

    const IconSun = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-sun"
            width={18}
            height={18}
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx={12} cy={12} r={4}></circle>
            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
        </svg>
    );

    const IconMoonStars = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-moon-stars"
            width={18}
            height={18}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
            <path d="M17 4a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"></path>
            <path d="M19 11h2m-1 -1v2"></path>
        </svg>
    );

    return (
        <AntdLayout.Header
            style={{
                backgroundColor: token.colorBgElevated,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "0px 24px",
                height: "64px",
            }}
        >
            {/*<Space style={{ marginRight: "12px" }}>*/}
            {/*    <TurnButton />*/}
            {/*</Space>*/}
            <SocketSelect onSelect={() => {
                setCollapsed(true);
            }} />
            {/*<Space style={{marginRight: '12px', marginTop: 25}} hidden={userPermission === 'admin'}>*/}
            {/*    <EspositoreSelect*/}
            {/*        onSelect={() => {*/}
            {/*            setCollapsed(false);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Space>*/}
            <Space style={{marginRight: '12px', marginTop: 25}} hidden={userPermission === 'admin'}>
                {/*<Text style={{color: props.theme === 'light' ? 'black' : 'whitesmoke'}}>Device: </Text>*/}
                <DeviceSelect
                    onSelect={() => {
                        setCollapsed(false);
                    }}
                />
            </Space>
            <Space style={{marginRight: '12px'}}>
                <StoreSelect
                    onSelect={() => {
                        setCollapsed(true);
                    }}
                    theme={props.theme}
                />
            </Space>

            <Switch
              checkedChildren="🌛"
              unCheckedChildren="🔆"
              //onChange={() => setMode(mode === "light" ? "dark" : "light")}
              onClick={() => {
                  props.setTheme(props.theme === "light" ? "dark" : "light");
              }}
              defaultChecked={props.theme === "dark"}
            />

            {/*<Space>*/}
            {/*    <AlertOutlined style={{ fontSize: 20}}/>*/}
            {/*</Space>*/}
            {/*<Dropdown overlay={menu}>*/}
            {/*  <Button type="link">*/}
            {/*    <Space>*/}
            {/*      <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />*/}
            {/*      {currentLocale === "en" ? "English" : "German"}*/}
            {/*      <DownOutlined />*/}
            {/*    </Space>*/}
            {/*  </Button>*/}
            {/*</Dropdown>*/}
            {/*<Space>*/}
            {/*    <Button style={{ border: 'none'}}*/}
            {/*            onClick={() => {*/}
            {/*                props.setTheme(props.theme === "light" ? "dark" : "light");*/}
            {/*            }}*/}
            {/*            icon={props.theme === "light" ? <IconMoonStars /> : <IconSun />}*/}
            {/*    />*/}
            {/*</Space>*/}
            <Space style={{marginLeft: '8px'}}>
                {/*{user?.name && (*/}
                {/*  <Text ellipsis strong>*/}
                {/*    {user.id}*/}
                {/*  </Text>*/}
                {/*)}*/}
                {/*{user?.username && <Avatar src={user?.avatar} alt={user?.username} />}*/}
                <RestockButton/>
            </Space>
        </AntdLayout.Header>
    );
};
