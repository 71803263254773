import { IResourceComponentsProps, useExport, useList, useTranslate, useUpdate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DateField,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Input, Space, Switch, Table, Tooltip} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {IWhareHouseMovement} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {useNavigate} from 'react-router-dom';

export const WhareHouseMovementList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const translate = useTranslate();
    const navigate = useNavigate();
    const {tableProps, tableQueryResult} = useTable<IWhareHouseMovement>({
        resource: 'whare-house-movements',
        meta: {populate: '*'},
        queryOptions: {
          enabled: true
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id ? store.id : 0
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IWhareHouseMovement>();
    const updateWhareHouseMovement = useUpdate<IWhareHouseMovement>();
    const toggle = (id: string, value: boolean) => {
        // console.log(id, value);
        updateWhareHouseMovement.mutateAsync({
            resource: 'whare-house-movements',
            id: id,
            values: {
                isLoad: false,
            }
        }).then(() =>{
            window.location.reload();
        })
    };

    // useEffect(() => {
    //     if(store.id) {
    //         tableQueryResult.refetch();
    //     }
    // }, [store.id]);
    return (
        <List
            key={Math.random()}
            title={translate('pages.whareHouseMovement.title', 'Documenti')}
            headerProps={{
                extra: [
                        <CreateButton disabled={(tableQueryResult && tableQueryResult.data) ? tableQueryResult.data?.data.reduce((acc, cur) => cur.isTemporary ? ++acc : acc, 0) > 0 : 0 > 0}/>,
                        // <ExportButton
                        //     onClick={triggerExport}
                        //     loading={exportLoading}
                        // />
                ],
            }}
        >
            <Table size="small" {...tableProps} rowKey="id"
                   // onRow={(record) => {
                   //     return {
                   //         onClick: () => {
                   //             console.log('whareHouseMovement', record);
                   //         },
                   //     };
                   // }}
                   sticky={true}
                   rowClassName={(record, index) => (record.total !== record.totalExt ? "red" : "")}
                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column
                    dataIndex="doc_type"
                    key="doc_type"
                    title={translate('pages.whareHouseMovement.description', 'Descrizione')}
                    render={(value, record: IWhareHouseMovement, index) => {
                        return (value?.description)
                    }}
                />
                <Table.Column
                    dataIndex="DDT Data"
                    key="loadDate"
                    title={translate('pages.whareHouseMovement.loadDate', 'Data')}
                    render={(value, record: IWhareHouseMovement, index) => {
                        return (
                            <DateField value={record?.loadDate} format={'DD/MM/YYYY'}></DateField>
                        )
                    }}
                />
                <Table.Column
                    dataIndex="docNumberExt"
                    key="docNumberExt"
                    title={translate('pages.whareHouseMovement.docNumber', 'Numero')}
                    render={(value) => (
                        value
                    )}
                />
                <Table.Column
                    dataIndex="total"
                    key="total"
                    title={translate('pages.whareHouseMovement.total', 'Totale')}
                    render={(value) => (
                        Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) + ' €'
                    )}
                />
                <Table.Column<{ id: string, isTemporary: boolean, isLoad: boolean }>
                    title={translate('pages.whareHouseMovement.action', 'Azioni')}
                    dataIndex="actions"
                    key="actions"
                    render={(_, record) => (
                        <Space>
                            <Tooltip placement={'top'} title={record.isLoad ? 'Per Visualizzare il documento devi prima sbloccare il lucchetto' : 'Visualizza'}>
                            <EditButton
                                disabled={record.isLoad}
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                        icon={<Icons.EyeOutlined />}
                            />
                            </Tooltip>
                            <Tooltip placement={'top'} title={'Elimina'}>
                            <DeleteButton hidden={!record.isTemporary}
                                          mutationMode={'undoable'}
                                          hideText
                                          size="small"
                                          successNotification={false}
                                          recordItemId={record.id}
                            />
                            </Tooltip>
                            <Tooltip placement={'top'} title={'Sblocca Carico'}>
                                <Button type="primary" size={'small'} onClick={() => toggle(record.id, record.isLoad)} hidden={!record.isLoad}
                                        icon={record.isLoad ? <Icons.UnlockOutlined /> : <Icons.LockOutlined />}>
                                </Button>
                            </Tooltip>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
